<template>
  <v-card>
    <v-app-bar dark color="#313131" flat>
      <v-card-title>
          <span class="headline">Visualisation d'un contact</span>
      </v-card-title>
    </v-app-bar>

    <v-card-text>
      <v-container class="mt-4">
        <v-row align="center">
          <v-col cols="4">
            <p><span style="font-weight: bold;">Prénom :</span>{{ contact.firstname }}</p>
            <p><span style="font-weight: bold;">Nom :</span>{{ contact.lastname }}</p>
            <v-divider></v-divider>
            <p><span style="font-weight: bold;">Mail :</span>{{ contact.email }}</p>
            <p><span style="font-weight: bold;">Fixe  :</span>{{ contact.phone }}</p>
            <p><span style="font-weight: bold;">Mobile :</span>{{ contact.phone_mobile }}</p>
            <v-divider></v-divider>
            <p><span style="font-weight: bold;">
              Service :</span>{{ contact.service ? contact.service.name : '' }}</p>
            <p><span style="font-weight: bold;">Rôle :</span>{{ contact.role }}</p>
          </v-col>
          <v-col cols="8">
            <p>
              <span style="font-weight: bold;">Organisation :</span>
              {{ contact.organisation ? contact.organisation.name : '' }}
            </p>
            <p><span style="font-weight: bold;">URL :</span>{{ contact.url }}</p>
            <p><span style="font-weight: bold;">Owner :</span>
            {{ contact.owner ? contact.owner.name : '' }}
            </p>
            <v-divider></v-divider>
            <p><span style="font-weight: bold;">Décideur :</span>{{ contact.decision_maker }}</p>
          </v-col>
        </v-row>
      </v-container>
      <v-container>
        <h2 class="mb-2">Formations</h2>
        <v-data-table :headers="headers"
                      hide-default-footer
                      :items="contact.formations">
          <template #item.date="{ item }">
            {{ moment(item.date).format('DD/MM/YY') }}
          </template>
        </v-data-table>
      </v-container>
    </v-card-text>

    <v-footer padless>
      <v-spacer />
      <v-card-actions>
        <v-btn
          color="primary"
          text
          exact
          :to="{ name: 'dashboard_contacts' }"
        >
          Fermer
        </v-btn>
      </v-card-actions>
    </v-footer>
  </v-card>
</template>

<script>
import moment from 'moment';
import { getContact } from '@/services/contact.service';
import { deleteOrganisation } from '@/services/organisation.service';

export default {
  name: 'view-contact',

  created() {
    // this.fetchContact();
  },

  data: () => ({
    headers: [
      {
        text: 'Equipement web',
        align: 'start',
        sortable: false,
        value: 'equipment.name',
      },
      { text: 'Date', value: 'date' },
      { text: 'Niveau', value: 'level.name' },
    ],
    loading: false,
    search: null,
    organisations: [],
    dialogNew: false,
    dialogEdit: false,
    contact: {
      service: {},
      organisation: {},
      owner: {},
    },
  }),
  methods: {
    moment(date) {
      return moment(date);
    },
    fetchContact(newRoute) {
      this.loading = true;
      getContact(decodeURIComponent(newRoute.params.id))
        .then((value) => {
          this.contact = value.data;
          console.log(value.data);
        })
        .catch(console.error)
        .finally(() => {
          this.loading = false;
        });
    },
    getCustomerTo(id) {
      return {
        name: 'dashboard_edit_client',
        params: {
          clientId: id,
        },
      };
    },
    deleteOrganisation(id) {
      deleteOrganisation(id)
        .then(() => {
          console.log('success!');
          this.fetchOrganisations();
        })
        .catch(console.error);
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(newRoute) {
        this.dialogNew = newRoute.meta?.showDialogNew;
        this.dialogEdit = newRoute.meta?.showDialogEdit;
        this.fetchContact(newRoute);
      },
    },
  },
};
</script>

<style>
  .required .v-input::after {
    content: "*";
    color: #ff0000;
    margin-left: 1rem;
  }
</style>
