<template>
  <v-card>
    <v-app-bar dark color="#313131" flat>
      <v-card-title>
          <span class="headline">Visualisation d'un abonnement</span>
      </v-card-title>
    </v-app-bar>

    <v-card-text>
      <v-container class="mt-4">
        <v-row align="center">
          <v-col cols="4"
              class="required">
            <p><span style="font-weight: bold;">Nom :</span>{{subscription.name}}</p>
          </v-col>
          <v-col cols="4"
              class="required">
            <p>
              <span style="font-weight: bold;">Début :</span>
              {{subscription.start_date}}
            </p>
          </v-col>
          <v-col cols="4"
              class="required">
            <p>
              <span style="font-weight: bold;">Fin :</span>
              {{subscription.deadline}}
            </p>
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col cols="4"
              class="required">
            <p><span style="font-weight: bold;">Montant :</span>{{subscription.price}}</p>
          </v-col>
          <v-col cols="4">
            <p><span style="font-weight: bold;" v-if="subscription.organisation">
              Organisation :</span>
            {{ subscription.organisation ? subscription.organisation.name : '' }}
            </p>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>

    <v-footer padless>
      <v-spacer />
      <v-card-actions>
        <v-btn
          color="primary"
          text
          exact
          :to="{ name: 'dashboard_subscriptions' }"
        >
          Fermer
        </v-btn>
      </v-card-actions>
    </v-footer>
  </v-card>
</template>

<script>
import { getSubscription, deleteSubscription } from '@/services/subscription.service';

export default {
  name: 'view-subscription',
  created() {
    // this.fetchOrganisation();
  },

  data: () => ({
    loading: false,
    subscription: {
    },
    dialogNew: false,
    dialogEdit: false,
  }),
  methods: {
    fetchSubscription(newRoute) {
      this.loading = true;
      getSubscription(decodeURIComponent(newRoute.params.id))
        .then((value) => {
          console.log(value);
          this.subscription = value.data;
        })
        .catch(console.error)
        .finally(() => {
          this.loading = false;
        });
    },
    getSubscriptionTo(id) {
      return {
        name: 'dashboard_edit_subscription',
        params: {
          id,
        },
      };
    },
    deleteSubscription(id) {
      deleteSubscription(id)
        .then(() => {
          console.log('success!');
        })
        .catch(console.error);
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(newRoute) {
        this.dialogNew = newRoute.meta?.showDialogNew;
        this.dialogEdit = newRoute.meta?.showDialogEdit;
        this.fetchSubscription(newRoute);
      },
    },
  },
};
</script>

<style>
  .required .v-input::after {
    content: "*";
    color: #ff0000;
    margin-left: 1rem;
  }
</style>
