<template>
  <div>
    <!-- Email de récupération -->
    <v-container class="my-4">
      <v-row class="mb-5 text-h6 black--text font-weight-black">
        Mot de passe
      </v-row>
      <v-row align="center" class="py-4">
        Le nouveau mot de passe sera envoyé à l'adresse :
        <v-responsive max-width="250" class="mx-6">
          <v-text-field append-icon="mdi-pencil" v-model="value.mail" />
        </v-responsive>
        <v-btn
          rounded
          color="#5b5b5b"
          dark
          class="font-weight-regular"
          depressed
        >
          Réinitialiser le mot de passe
        </v-btn>
      </v-row>
    </v-container>
    <v-divider />
    <!-- Documents -->
    <v-container class="my-4">
      <v-row class="mb-5 text-h6 black--text font-weight-black">
        Ajouter un document
      </v-row>
      <!-- Initial value (_vm.new_doc) -->
      <v-row align="center" v-if="value && value.documents && value.documents.length < 1">
        <v-btn
          rounded
          color="#5b5b5b"
          dark
          @click="pickFile(-1)"
          class="font-weight-regular"
          depressed
        >
          <v-icon left>mdi-upload</v-icon>
          Uploader le PDF
        </v-btn>

        <input
          type="file"
          ref="init_document"
          hidden style="display: none"
          :name="`document-init`"
          @change="onFilePicked"
        >

        <v-spacer />

        <v-text-field
          append-icon="mdi-pencil"
          label="Titre du document"
          v-model="new_doc.file.name"
        />

        <v-spacer />

        <v-combobox
          clearable
          persistent-hint
          hint="Vous pouvez fournir une valeur qui n'existe pas encore"
          label="Catégorie"
          v-model="new_doc.category"
        >
        </v-combobox>

        <v-spacer />

        <v-btn
          rounded
          color="#46baee"
          dark
          @click="addDoc"
          depressed
          class="font-weight-regular"
        >
          <v-icon left>mdi-plus</v-icon>
          Ajouter
        </v-btn>
      </v-row>
      <!-- All documents stored for this customer -->
      <template v-for="(doc, d) in value.documents">
        <v-row
          align="center"
          :class="{ 'mt-2': d > 0 }"
          :key="`document-${d}`"
        >
          <v-btn
            rounded
            color="#5b5b5b"
            dark
            @click="pickFile(d)"
            depressed
            class="font-weight-regular"
          >
            <v-icon left>mdi-upload</v-icon>
            Uploader le PDF
          </v-btn>

          <input
            type="file"
            ref="documents"
            hidden style="display: none"
            :name="`document-${d}`"
            @change="onFilePicked"
          >

          <v-spacer />

          <v-text-field
            append-icon="mdi-pencil"
            label="Titre du document"
            v-model="doc.file.name"
          />

          <v-spacer />

          <v-combobox
            clearable
            persistent-hint
            hint="Vous pouvez fournir une valeur qui n'existe pas encore"
            label="Catégorie"
            :items="categories"
            v-model="doc.category"
          >
          </v-combobox>

          <v-spacer />

          <v-btn
            rounded
            color="#46baee"
            dark
            @click="addDoc"
            depressed
            class="font-weight-regular"
          >
            <v-icon left>mdi-plus</v-icon>
            Ajouter
          </v-btn>
        </v-row>
      </template>
    </v-container>
    <v-divider />
    <!-- Hosting, NDD, maintenance -->
    <v-container class="my-4">
      <v-row class="pa-4">
        <v-col cols="12" md="6" class="mb-6">
          <v-row class="mb-5 text-h6 black--text font-weight-black" justify="center">
            Hébergement
          </v-row>
          <v-row align="center" class="py-2 required" justify="center">
            Hébergeur :
            <v-responsive max-width="250" class="mx-6">
              <v-text-field
                :rules="[(value) => !!value || value.length <= 0 || 'Ce champs est requis.']"
                append-icon="mdi-pencil"
                v-model="value.hosting.host" />
            </v-responsive>
          </v-row>
          <v-alert type="error" v-if="errors.host && errors.host.length > 0">
            {{ errors.host }}
          </v-alert>
          <v-row align="center" class="py-2" justify="center">
            Valable du :
            <v-responsive max-width="120" class="mx-6">
              <DatePicker :date.sync="value.hosting.from" />
            </v-responsive>
            au :
            <v-responsive max-width="120" class="mx-6">
              <DatePicker :date.sync="value.hosting.until" />
            </v-responsive>
          </v-row>
        </v-col>
        <v-col
          v-for="(domain, d) in value.domains"
          cols="12"
          md="6"
          class="mb-6"
          :key="`domaine-${d}`"
        >
          <v-row class="mb-5 text-h6 black--text font-weight-black" justify="center">
            Nom de domaine
          </v-row>
          <v-row align="center" class="py-2 required" justify="center">
            Nom de domaine :
            <v-responsive max-width="250" class="mx-6">
              <v-text-field
                :rules="[(value) => !!value || value.length <= 0 || 'Ce champs est requis.']"
                append-icon="mdi-pencil"
                v-model="domain.name" />
            </v-responsive>
          </v-row>
          <v-alert type="error" v-if="errors.domain && errors.domain.length > 0">
            {{ errors.domain }}
          </v-alert>
          <v-row align="center" class="py-2" justify="center">
            Valable du :
            <v-responsive max-width="120" class="mx-6">
              <DatePicker :date.sync="domain.from" />
            </v-responsive>
            au :
            <v-responsive max-width="120" class="mx-6">
              <DatePicker :date.sync="domain.until" />
            </v-responsive>
          </v-row>
        </v-col>
        <v-col cols="12" md="6" class="mb-6">
          <v-row class="mb-5 text-h6 black--text font-weight-black" justify="center">
            Maintenance
          </v-row>
          <v-row align="center" class="py-2" justify="center">
            Valable jusqu'au :
            <v-responsive max-width="120" class="mx-6">
              <DatePicker :date.sync="value.maintenance.until" />
            </v-responsive>
          </v-row>
        </v-col>
      </v-row>
      <!-- <v-row justify="center">
        <v-btn
          rounded
          color="#5b5b5b"
          dark
        >
          Enregistrer les modifications
        </v-btn>
      </v-row> -->
    </v-container>
  </div>
</template>

<script>
import DatePicker from '@/components/DatePicker.vue';

export default {
  name: 'BaseClientForm',
  components: { DatePicker },
  data: () => ({
    new_doc: {
      file: { name: '' },
      category: '',
    },
    files: new Map(),
    myDate: new Date().toISOString().substring(0, 10),
  }),
  props: {
    value: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    addDoc() {
      if (!this.value.documents || this.value.documents.length < 1) {
        const newDoc = {
          file: { name: this.new_doc?.file?.name || '' },
          category: this.new_doc?.category || '',
        };

        this.value.documents.push(newDoc);
      }
      this.value.documents.push({ file: { name: '' }, category: '' });
    },
    pickFile(i) {
      console.log(i);
      console.log(this.$refs.documents);
      if (i >= 0) {
        this.$refs.documents[i].click();
      } else if (i === -1) {
        this.$refs.init_document.click();
      }
    },
    onFilePicked(event) {
      if (event.target) {
        console.log(event.target.name);
        if (event.target.name === 'document-init') {
          this.files.set('document-0', event.target.files[0]);
        } else {
          this.files.set(event.target.name, event.target.files[0]);
        }
        this.$emit('change', this.files);
      }
    },
  },
  computed: {
    categories() {
      if (!this.value || !this.value.documents || !(this.value?.documents?.length > 0)) return [];
      const categorySet = new Set(this.value.documents.map((doc) => {
        if (doc.category) return doc.category;
        return null;
      }));
      categorySet.delete(null);
      return Array.from(categorySet);
    },
  },
  watch: {
    new_doc: {
      handler(doc) { this.value.documents[0] = doc; },
      deep: true,
    },
  },
};
</script>

<style scoped>

</style>
