<template>
  <v-form class="ma-2"
          ref="form"
          v-model="valid"
          lazy-validation>
    <v-dialog v-model="validationDialog"
              type="error"
              width="500">
      <v-alert type="error">
        Une erreur s'est produite.
      </v-alert>
    </v-dialog>
    <v-row>
      <v-row class="mt-2">
        <v-col cols="6"
               class="required ml-3">
          <v-row class="d-flex flex-nowrap">
            <v-autocomplete v-model="form.product"
                            :items="products.items"
                            item-text="name"
                            item-value="id"
                            dense
                            outlined
                            :loading="loadingProducts"
                            :rules="[v => !!v || 'Le produit est requis']"
                            :label="products.catalog.name"
                            required></v-autocomplete>
            <v-btn fab
                   x-small
                   dark
                   outlined
                   class="ma-auto ml-2 mt-3"
                   @click="editCatalog(products.catalog.id)"
                   color="black">
              <v-icon dark>
                mdi-plus
              </v-icon>
            </v-btn>
          </v-row>
          <v-dialog v-model="catalogDialog"
                    width="500">
            <CatalogForm :catalogId="selectedCatalog" v-on:closeCatalog="closeCatalog" />
          </v-dialog>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="required">
          <v-text-field v-model="form.url"
                        outlined
                        :rules="[v => !!v || 'L\'url est requise']"
                        label="Url"
                        required>
          </v-text-field>
        </v-col>
        <v-col class="required">
          <v-menu v-model="subscriptionDateDialog"
                  :close-on-content-click="false"
                  :nudge-right="0"
                  transition="scale-transition"
                  offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="form.subscription_date"
                            outlined
                            label="Date de souscription"
                            readonly
                            required
                            v-bind="attrs"
                            :rules="[v => !!v || 'Une date est requise']"
                            v-on="on"></v-text-field>
            </template>
            <v-date-picker v-model="form.subscription_date"
                           @input="subscriptionDateDialog = false"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col class="required">
          <v-menu v-model="deadlineDateDialog"
                  :close-on-content-click="false"
                  :nudge-right="0"
                  transition="scale-transition"
                  offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="form.deadline"
                            outlined
                            label="Echéance"
                            :rules="[v => !!v || 'Une date est requise']"
                            required
                            readonly
                            v-bind="attrs"
                            v-on="on"></v-text-field>
            </template>
            <v-date-picker v-model="form.deadline"
                           @input="deadlineDateDialog = false"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col class="required">
          <v-text-field outlined
                        v-model.number="form.price"
                        :rules="priceRules"
                        label="Montant"
                        required></v-text-field>
        </v-col>
      </v-row>
    </v-row>
    <v-spacer />
    <v-row class="ma-2 ml-0 d-flex align-center">
      <h2 class="mb-2">Contacts</h2>
      <v-btn small outlined @click.stop="openContact" class="ml-2">
        <v-icon dark>
          mdi-plus
        </v-icon>
        <span class="d-sr-only">Ajouter un contact</span>
      </v-btn>
    </v-row>
    <v-dialog v-model="contactDialog"
              persistent
              width="800">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Nouveau contact
        </v-card-title>

        <v-card-text>
          <ContactForm ref="contactForm"
                       :isEditingContact="form.contacts[isEditingContact]"
                       :isInsideOrganisation="true"
                       />
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1"
                 text
                 @click="contactDialog = false">
            Annuler
          </v-btn>
          <v-btn color="green darken-1"
                 text
                 @click="validateContact">
            Enregistrer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-spacer />
    <v-container>
      <v-row v-for="(contact, id) in form.contacts" :key="id">
        <v-col>
          <v-container>
            <v-row style="border-bottom:solid 1px grey;" class="mb-4">
              <v-col cols="2">{{contact.firstname}} {{contact.lastname}}</v-col>
              <v-col cols="2">{{contact.email}}</v-col>
              <v-col cols="2" v-if="contact.phone">{{contact.phone}}</v-col>
              <v-col cols="2" v-if="contact.phone_mobile">{{contact.phone_mobile}}</v-col>
              <v-col>
                <v-row v-for="(formation, index) in contact.formations" :key="index">
                  <v-col>
                    <v-chip color="grey"
                            text-color="white"
                            dark>
                      {{formation.equipment.name}}
                    </v-chip>
                  </v-col>
                  <v-col>
                    <v-chip color="grey"
                            text-color="white"
                            dark>
                      {{formation.level.name}}
                    </v-chip>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
        <v-col cols="2">
          <v-btn icon @click="openEditContact(id)">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn icon @click="deleteContact(id)">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import { getCatalogByName } from '@/services/catalog.service';
import CatalogForm from '@/components/CatalogForm.vue';
import {
  getContact, createContact, updateContact, deleteContact,
} from '@/services/contact.service';
import ContactForm from '@/components/ContactForm.vue';
import { mapActions } from 'vuex';

export default {
  name: 'MaintenanceForm',
  props: ['maintenance'],
  components: {
    ContactForm,
    CatalogForm,
  },
  data: () => ({
    emptyContact: {
      date: new Date().toISOString().substr(0, 10),
      formations: [],
      decision_maker: false,
      role: '',
    },
    isEditingContact: null,
    form_contact: null,
    priceRules: [
      (v) => !!v || 'Le montant est requis',
      (v) => !Number.isNaN(Number(v)) || 'Le prix doit être un chiffre.',
    ],
    valid: true,
    validationDialog: false,
    deadlineDateDialog: false,
    subscriptionDateDialog: false,
    contactDialog: false,
    products: {
      catalog: {},
      items: [],
    },
    loadingProducts: false,
    catalogDialog: false,
    selectedCatalog: null,
  }),
  created() {
    this.fetchCatalogs();
    this.$on('validateFormMaintenance', this.validate);
  },
  computed: {
    form() {
      return this.maintenance ? this.maintenance : {
        organisation: null,
        price: null,
        url: null,
        contacts: [],
        subscription_date: null,
        deadline: null,
      };
    },
  },
  methods: {
    fetchCatalogs() {
      this.loadingProducts = true;
      getCatalogByName('Produit').then((res) => {
        this.products = res.data;
      }).catch((err) => {
        console.log(err);
      }).finally(() => {
        this.loadingProducts = false;
      });
    },
    editCatalog(catalogId) {
      this.catalogDialog = true;
      this.selectedCatalog = catalogId;
    },
    closeCatalog() {
      this.fetchCatalogs();
      this.catalogDialog = false;
      this.selectedCatalog = null;
    },
    validate() {
      // send to parent
      if (this.$refs.form.validate()) {
        console.log('valid maintenance');
        if (this.form.product) {
          this.form.product = this.products.items.find((el) => el.id === this.form.product)
            || this.form.product;
        }
        return this.form;
      }
      return null;
    },
    openContact() {
      this.isEditingContact = null;
      console.log('isEditing');
      console.log(this.isEditingContact);
      this.contactDialog = true;
    },
    addContact(data) {
      createContact(data).then((res) => {
        console.log(res);
        getContact(res.data.contact.id).then((result) => {
          this.form.contacts.unshift(result.data);
        }).catch((error) => {
          console.log(error);
          this.validationDialog = true;
        });
      }).catch((error) => {
        console.log(error);
        this.validationDialog = true;
      }).finally(() => {
        this.contactDialog = false;
        this.isEditingContact = null;
        console.log('isEditing');
        console.log(this.isEditingContact);
      });
    },
    updateContact(data) {
      updateContact(this.form.contacts[this.isEditingContact].id, data).then((res) => {
        console.log(res);
        getContact(res.data.contact.id).then((result) => {
          console.log(result);
          console.log('for id');
          console.log(this.isEditingContact);
          this.$set(this.form.contacts, this.isEditingContact, result.data);
          console.log(this.form.contacts[this.isEditingContact]);
          this.isEditingContact = null;
          console.log('isEditing');
          console.log(this.isEditingContact);
        }).catch((error) => {
          console.log(error);
          this.validationDialog = true;
        });
      }).catch((error) => {
        console.log(error);
        this.validationDialog = true;
      }).finally(() => {
        this.contactDialog = false;
      });
    },
    deleteContact(id) {
      deleteContact(this.form.contacts[id].id).then((res) => {
        console.log(res);
        this.form.contacts.splice(id, 1);
      }).catch((error) => {
        console.log(error);
      });
    },
    validateContact() {
      const valid = this.$refs.contactForm.validate();
      if (valid) {
        console.log('isEditing');
        console.log(this.isEditingContact);
        if (this.isEditingContact !== null) {
          console.log('update');
          this.updateContact(valid);
        } else {
          console.log('add');
          this.addContact(valid);
        }
      } else {
        console.log('error in child form!');
      }

      /*
      this.form.contacts.unshift(this.emptyContact);
      this.isEditingContact = 0;
      this.form_contact = this.form.contacts[this.isEditingContact];
      */
    },
    openEditContact(id) {
      this.contactDialog = true;
      this.isEditingContact = id;
      console.log('isEditing');
      console.log(this.isEditingContact);
    },
    ...mapActions({
      register: 'auth/register',
    }),
  },
};
</script>

<style scoped>

</style>
