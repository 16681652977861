import axios from 'axios';
import authHeader from './auth-header';

export function getCustomers() {
  return axios.get(`${process.env.VUE_APP_API_URL}customers`, { headers: authHeader() });
}

export function getCustomer(id) {
  return axios.get(`${process.env.VUE_APP_API_URL}customer/${id}`, { headers: authHeader() });
}

export function postCustomer(data) {
  return axios.post(`${process.env.VUE_APP_API_URL}customers`, data, {
    headers: authHeader(),
    'Content-Type': 'multipart/form-data', // necessary to send files
  });
}

export function updateCustomer(id, data) {
  return axios.patch(`${process.env.VUE_APP_API_URL}customer/${id}`, data, {
    headers: authHeader(),
    'Content-Type': 'multipart/form-data', // necessary to send files
  });
}

export function deleteCustomer(id) {
  return axios.delete(`${process.env.VUE_APP_API_URL}customer/${id}`, { headers: authHeader() });
}

export function getUsers() {
  return axios.get(`${process.env.VUE_APP_API_URL}users`, { headers: authHeader() });
}
