<template>
  <v-container>
    <v-data-table
      :headers="headers"
      :items="users"
      :search="search"
      hide-default-footer
      calculate-widths
      :loading="loading"
    >
      <template #top>
        <v-container>
          <v-row align="center">
            <v-btn
              rounded
              color="primary"
              dark
              class="mb-2"
              :to="{ name: 'dashboard_new_user' }"
            >
              Ajouter un utilisateur
              <v-icon right>
                mdi-plus
              </v-icon>
            </v-btn>
            <v-dialog
              v-model="dialogNew"
              persistent
              scrollable
              :fullscreen="$vuetify.breakpoint.mdAndDown"
              max-width="1200px"
            >
              <router-view />
            </v-dialog>
            <v-dialog
              v-model="dialogEdit"
              persistent
              scrollable
              :fullscreen="$vuetify.breakpoint.mdAndDown"
              max-width="1200px"
            >
              <router-view />
            </v-dialog>

            <v-spacer />

            <v-text-field
              v-model="search"
              label="Rechercher"
              append-icon="mdi-magnify"
              single-line
              hide-details
            />
          </v-row>
        </v-container>
      </template>

      <template #item.admin="{ item }">
        <span :class="{
          'font-weight-medium': item.admin,
          'font-weight-regular': !item.admin,
        }">
          {{ item.admin ? 'Administrateur' : 'Client' }}
        </span>
      </template>

      <template #item.customer="{ item }">
        <router-link
          v-if="item.Customer"
          :to="getCustomerTo(item.Customer)"
        >
          Lien
        </router-link>
        <span v-else>Non</span>
      </template>

      <template #item.actions="{ item }">
        <v-btn
          icon
          class="mr-2"
          :to="{ name: 'dashboard_edit_user', params: { email: encodeURIComponent(item.email) } }"
        >
          <v-icon small>mdi-pencil</v-icon>
        </v-btn>

        <v-btn icon @click="deleteUser(item.email)">
          <v-icon small>mdi-delete</v-icon>
        </v-btn>
      </template>

      <template #no-results>
        Aucun résultat trouvé :/
      </template>

      <template #no-data>
        Aucun utilisateur n'a pu être récupéré :/
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { getUsers } from '@/services/admin.service';
import { deleteUser } from '@/services/user.service';

export default {
  name: 'Users',

  created() {
    this.fetchUsers();
  },

  data: () => ({
    headers: [
      {
        text: 'Nom',
        align: 'start',
        value: 'name',
      },
      {
        text: 'Email',
        value: 'email',
      },
      {
        text: 'Type de compte',
        value: 'admin',
      },
      {
        text: 'Compte client',
        sortable: false,
        value: 'customer',
      },
      {
        text: 'Actions',
        sortable: false,
        value: 'actions',
      },
    ],
    loading: false,
    search: null,
    users: [],
    dialogNew: false,
    dialogEdit: false,
  }),
  methods: {
    fetchUsers() {
      this.loading = true;
      getUsers()
        .then((value) => {
          this.users = value.data;
        })
        .catch(console.error)
        .finally(() => {
          this.loading = false;
        });
    },
    getCustomerTo(id) {
      return {
        name: 'dashboard_edit_client',
        params: {
          clientId: id,
        },
      };
    },
    deleteUser(email) {
      deleteUser(email)
        .then(() => {
          console.log('success!');
          this.fetchUsers();
        })
        .catch(console.error);
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(newRoute) {
        this.dialogNew = newRoute.meta?.showDialogNew;
        this.dialogEdit = newRoute.meta?.showDialogEdit;
        this.fetchUsers();
      },
    },
  },
};
</script>

<style scoped>

</style>
