<template>
  <v-card>
    <v-app-bar dark color="#313131" flat>
      <v-card-title>
          <span class="headline">Édition de la proposition {{ form.type || '' }}</span>
      </v-card-title>
    </v-app-bar>

    <v-card-text>
      <v-container class="mt-4">
        <v-form class="ma-2"
                ref="form"
                v-model="valid"
                lazy-validation>
          <v-row>
            <v-col class="required">
              <v-text-field outlined
                            v-model="form.type"
                            :rules="[v => !!v || 'Le type est requis']"
                            label="Type"
                            v-model.trim="$v.form.type.$model"
                            required></v-text-field>
            </v-col>
            <v-col>
              <v-text-field outlined
                            v-model="form.description"
                            label="Description"
                            v-model.trim="$v.form.description.$model"
                            ></v-text-field>
            </v-col>
            <v-col>
              <v-select v-model="form.organisation"
                        :items="organisations"
                        item-text="name"
                        item-value="id"
                        persistent-hint
                        outlined
                        label="Organisation"
                        :loading="loadingOrganisations"
                        disabled></v-select>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="4">
              <v-text-field outlined
                            v-model.number="form.price"
                            :rules="priceRules"
                            label="Montant"
                            v-model.trim="$v.form.price.$model"
                            ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field outlined
                            v-model.number="form.percent"
                            :rules="percentRules"
                            label="Pourcentage"
                            v-model.trim="$v.form.percent.$model"
                            ></v-text-field>
            </v-col>
            <v-col>
              <v-menu v-model="startDateDialog"
                      :close-on-content-click="false"
                      :nudge-right="0"
                      transition="scale-transition"
                      offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="form.date"
                                outlined
                                label="Date"
                                readonly
                                v-bind="attrs"
                                v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="form.date"
                               @input="startDateDialog = false"></v-date-picker>
              </v-menu>
            </v-col>
            <v-col>
              <v-menu v-model="endDateDialog"
                      :close-on-content-click="false"
                      :nudge-right="0"
                      transition="scale-transition"
                      offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="form.cde"
                                outlined
                                label="CDE"
                                readonly
                                v-bind="attrs"
                                v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="form.cde"
                               @input="endDateDialog = false"></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card-text>

    <v-footer padless>
      <v-spacer />
      <v-card-actions>
        <v-btn
          color="primary"
          text
          exact
          :to="{ name: 'dashboard_propositions' }"
        >
          Annuler
        </v-btn>
        <v-btn
          color="primary"
          text
          exact
          @click="validate"
        >
          Enregistrer
        </v-btn>
      </v-card-actions>
    </v-footer>
  </v-card>
</template>

<script>
import { required, numeric, between } from 'vuelidate/lib/validators';
import { getOrganisations } from '../../services/organisation.service';
import { getProposition, updateProposition } from '../../services/proposition.service';

export default {
  name: 'edit-proposition',
  validations: {
    form: {
      price: {
        numeric,
      },
      percent: {
        numeric,
        between: between(0, 100),
      },
      type: {
        required,
      },
      description: {
      },
    },
  },

  data: () => ({
    priceRules: [
      (v) => Number.isInteger(Number(v)) || 'Le montant doit être un nombre.',
    ],
    percentRules: [
      (v) => Number.isInteger(Number(v)) || 'Le pourcentage doit être un nombre.',
      (v) => (Number(v) >= 0 && Number(v) <= 100) || 'Le pourcentage doit être compris entre 0 et 100 (inclus).',
    ],
    form: {
      type: null,
      description: null,
      organisation: null,
      price: null,
      percent: null,
      date: null,
      cde: null,
    },
    valid: true,
    startDateDialog: false,
    endDateDialog: false,
    organisations: [],
    loadingOrganisations: false,
  }),
  methods: {
    async fetchProposition(newRoute) {
      this.loadingOrganisations = true;
      getOrganisations().then((res) => {
        this.organisations = res.data;
      }).catch((err) => {
        console.log(err);
      }).finally(() => {
        this.loadingOrganisations = false;
      });

      getProposition(decodeURIComponent(newRoute.params.id)).then((value) => {
        this.form = value.data;
        if (this.form.organisation) {
          this.form.organisation = this.form.organisation.id;
        }
        this.form.date = new Date(this.form.date)
          .toISOString().substr(0, 10);
        this.form.cde = new Date(this.form.cde).toISOString().substr(0, 10);
      })
        .catch((err) => console.log(err));
    },
    validate() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$refs.form.validate();
      } else {
        updateProposition(this.form.id, this.form).then(() => {
          this.$router.push({ name: 'dashboard_propositions' });
        });
      }
    },
  },
  watch: {
    $route: {
      handler: 'fetchProposition',
      immediate: true,
    },
  },
};
</script>

<style>
  .required .v-input::after {
    content: "*";
    color: #ff0000;
    margin-left: 1rem;
  }
</style>
