<template>
  <v-card>
    <v-app-bar dark color="#313131" flat>
      <v-card-title>
          <span class="headline">Visualisation d'une organisation</span>
      </v-card-title>
    </v-app-bar>

    <v-card-text>
      <v-container class="mt-4">
        <v-row align="center">
          <v-col cols="4">
            <p><span style="font-weight: bold;">Code :</span> {{organisation.code || 'N/D'}}</p>
          </v-col>
          <v-col cols="4">
            <p><span style="font-weight: bold;">Siret :</span>{{organisation.siret}}</p>
          </v-col>
          <v-col cols="4">
            <p><span style="font-weight: bold;">Nom :</span>{{organisation.name}}</p>
          </v-col>
          <v-col cols="4">
            <p>
              <span style="font-weight: bold;">Date de création :</span>
              {{organisation.date ? moment(organisation.date).format('DD/MM/YY') : '' }}
            </p>
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col cols="4">
            <p><span style="font-weight: bold;">Adresse 1 :</span>{{organisation.address1}}</p>
          </v-col>
          <v-col cols="4">
            <p><span style="font-weight: bold;">Adresse 2 :</span>{{organisation.address2}}</p>
          </v-col>
          <v-col cols="4">
            <p><span style="font-weight: bold;">Adresse 3 :</span>{{organisation.address3}}</p>
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col cols="4">
            <p><span style="font-weight: bold;">Pays :</span>{{organisation.country}}</p>
          </v-col>
          <v-col cols="4">
            <p><span style="font-weight: bold;">Code Postal :</span>{{organisation.postalCode}}</p>
          </v-col>
          <v-col cols="4">
            <p><span style="font-weight: bold;">Ville :</span>{{organisation.city}}</p>
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col cols="4">
            <p><span style="font-weight: bold;">Code APE :</span>{{organisation.APECode}}</p>
          </v-col>
          <v-col cols="4">
            <p><span style="font-weight: bold;">Téléphone :</span>{{organisation.phone}}</p>
          </v-col>
          <v-col cols="4">
            <p><span style="font-weight: bold;">E-mail :</span>{{organisation.email}}</p>
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col cols="4">
            <p><span style="font-weight: bold;">CA :</span>{{organisation.ca}}</p>
          </v-col>
          <v-col cols="4">
            <p><span style="font-weight: bold;">CA - MC :</span>{{organisation.caMc}}</p>
          </v-col>
          <v-col cols="4">
            <p><span style="font-weight: bold;">Effectif :</span>{{organisation.workforce}}</p>
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col cols="4">
            <p>
              <span style="font-weight: bold;">Groupe / maison mère :</span>
              {{organisation.societyGroup}}
            </p>
          </v-col>
          <v-col cols="4">
            <p><span style="font-weight: bold;">Notes :</span>{{organisation.notes}}</p>
          </v-col>
          <v-col cols="4">
            <p>
              <span style="font-weight: bold;" v-if="organisation.sponsor">Parrain :</span>
              {{organisation.sponsor ? organisation.sponsor.name : ''}}
            </p>
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col cols="4">
            <p><span style="font-weight: bold;">URL :</span>{{organisation.url}}</p>
          </v-col>
          <v-col cols="4">
            <p>
              <span style="font-weight: bold;">Modèle économique :</span>
              {{organisation.societyType ? organisation.societyType.name : 'Non renseigné'}}
            </p>
          </v-col>
          <v-col cols="4">
            <p>
              <span style="font-weight: bold;" v-if="organisation.family">Famille :</span>
              {{organisation.family ? organisation.family.name: '' }}
            </p>
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col cols="4">
            <p>
              <span style="font-weight: bold;" v-if="organisation.societyType">Type :</span>
              {{organisation.societyType ? organisation.societyType.name: '' }}
            </p>            </v-col>
            <v-col cols="2">
              <p><span style="font-weight: bold;">CA :</span>{{organisation.ca}}</p>
            </v-col>
            <v-col cols="2">
              <p><span style="font-weight: bold;">CA - MC :</span>{{organisation.caMc}}</p>
            </v-col>
            <v-col cols="2">
              <p><span style="font-weight: bold;">Effectif :</span>{{organisation.workforce}}</p>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="12">
              <p><span style="font-weight: bold;">Notes :</span>{{organisation.notes}}</p>
            </v-col>
          </v-row>
      </v-container>
      <v-spacer />
      <v-divider inset vertical role="presentation" />
      <v-spacer />
      <h2 class="mb-2">Contacts</h2>
      <template>
        <v-data-table :headers="contactsHeaders"
                      :items="contacts"
                      :items-per-page="5">
          <template #no-results>
            Aucun résultat trouvé :/
          </template>

          <template #no-data>
            Aucun contact n'a pu être récupéré :/
          </template>
        </v-data-table>
      </template>
      <v-spacer />
      <v-divider inset vertical role="presentation" />
      <v-spacer />
      <h2 class="mb-2">Maintenances</h2>
      <v-container>
        <v-row class="d-flex flex-nowrap" style="border-bottom:solid 1px grey;">
          <v-col cols="2">URL</v-col>
          <v-col cols="2">Date de souscription</v-col>
          <v-col cols="2">Echéance</v-col>
          <v-col cols="2">Montant</v-col>
        </v-row>
        <v-container v-for="(maintenance, index) in maintenances" :key="index">
          <v-row style="border-bottom:solid 1px grey;" class="mb-4">
            <v-col cols="2">{{maintenance.url}}</v-col>
            <v-col cols="2">
              <v-chip color="grey"
                      text-color="white"
                      v-if="maintenance.subscription_date"
                      dark>
                {{ moment(maintenance.subscription_date).format("DD/MM/YY") }}
              </v-chip>
            </v-col>
            <v-col cols="2">
              <v-chip :color="new Date(maintenance.deadline) <= (new Date())
                            .setHours(0,0,0,0) ? 'red' : 'green'"
                      text-color="white"
                      v-if="maintenance.deadline"
                      dark>
                {{ moment(maintenance.deadline).format("DD/MM/YY") }}
              </v-chip>
            </v-col>
            <v-col cols="2">{{maintenance.price}} €</v-col>
          </v-row>
          <v-row style="border-bottom:solid 1px grey;">
            <h3 class="mb-2">Contacts</h3>
          </v-row>
          <v-row v-for="(contact, index) in maintenance.contacts"
                 :key="index"
                 style="border-bottom:solid 1px grey;">
            <v-col cols="2">{{contact.firstname}} {{contact.lastname}}</v-col>
            <v-col cols="2">{{contact.url}}</v-col>
            <v-col cols="2">{{contact.phone}}</v-col>
            <v-col cols="2">{{contact.mobile_phone}}</v-col>
            <v-col>
              <v-row v-for="(formation, index) in contact.formations" :key="index">
                <v-col>
                  <v-chip color="grey"
                          text-color="white"
                          dark>
                    {{formation.equipment.name}}
                  </v-chip>
                </v-col>
                <v-col>
                  <v-chip color="grey"
                          text-color="white"
                          dark>
                    {{formation.level.name}}
                  </v-chip>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-container>
      <v-spacer />
      <v-divider inset vertical role="presentation" />
      <v-spacer />
      <h2 class="mb-2">Abonnements</h2>
      <template>
        <v-data-table :headers="subscriptionsHeaders"
                      :items="subscriptions"
                      :items-per-page="5">
          <template #item.start_date="{ item }">
            <v-chip color="grey"
                    text-color="white"
                      v-if="item.start_date"
                    dark>
              {{ moment(item.start_date).format('DD/MM/YY') }}
            </v-chip>
          </template>
          <template #item.deadline="{ item }">
            <v-chip :color="new Date(item.deadline) <= (new Date())
                            .setHours(0,0,0,0) ? 'red' : 'green'"
                    text-color="white"
                    v-if="item.deadline"
                    dark>
              {{ moment(item.deadline).format('DD/MM/YY') }}
            </v-chip>
          </template>
          <template #item.price="{ item }">
            {{ item.price }} €
          </template>
          <template #no-results>
            Aucun résultat trouvé :/
          </template>

          <template #no-data>
            Aucun abonnement n'a pu être récupéré :/
          </template>
        </v-data-table>
      </template>
      <v-spacer />
      <v-divider inset vertical role="presentation" />
      <v-spacer />
      <h2 class="mb-2">Equipements</h2>
      <template>
        <v-data-table :headers="equipmentsHeaders"
                      :items="equipments"
                      :items-per-page="5">
          <template #item.start_date="{ item }">
            <v-chip color="grey"
                    text-color="white"
                    v-if="item.start_date"
                    dark>
              {{ moment(item.start_date).format('DD/MM/YY') }}
            </v-chip>
          </template>
          <template #item.deadline="{ item }">
            <v-chip :color="new Date(item.deadline) <= (new Date())
                            .setHours(0,0,0,0) ? 'red' : 'green'"
                    text-color="white"
                    v-if="item.deadline"
                    dark>
              {{ moment(item.deadline).format('DD/MM/YY') }}
            </v-chip>
          </template>
          <template #item.price="{ item }">
            {{ item.price }} €
          </template>
          <template #no-results>
            Aucun résultat trouvé :/
          </template>

          <template #no-data>
            Aucun équipement n'a pu être récupéré :/
          </template>
        </v-data-table>
      </template>
      <v-spacer />
      <v-divider inset vertical role="presentation" />
      <v-spacer />
      <h2 class="mb-2">Propositions</h2>
      <template>
        <v-data-table :headers="propositionsHeaders"
                      :items="propositions"
                      :items-per-page="5">
          <template #item.percent="{ item }">
            <v-chip v-if="item.percent"
                    :color="getColor(item.percent)"
                    dark>
              {{ item.percent ? item.percent.name : '' }}
            </v-chip>
          </template>
          <template #item.cde="{ item }">
            <v-chip color="grey"
                    text-color="white"
                    v-if="item.cde"
                    dark>
              {{ moment(item.cde).format('DD/MM/YY') }}
            </v-chip>
          </template>
          <template #item.date="{ item }">
            <v-chip color="grey"
                    text-color="white"
                    v-if="item.date"
                    dark>
              {{ moment(item.date).format('DD/MM/YY') }}
            </v-chip>
          </template>
          <template #item.price="{ item }">
            {{ item.price }} €
          </template>
          <template #no-results>
            Aucun résultat trouvé :/
          </template>

          <template #no-data>
            Aucune proposition n'a pu être récupérée :/
          </template>
        </v-data-table>
      </template>
    </v-card-text>

    <v-footer padless>
      <v-spacer />
      <v-card-actions>
        <v-btn
          color="primary"
          text
          exact
          :to="{ name: 'dashboard_organisations' }"
        >
          Fermer
        </v-btn>
      </v-card-actions>
    </v-footer>
  </v-card>
</template>

<script>
import moment from 'moment';
import { getOrganisation, deleteOrganisation } from '@/services/organisation.service';
import { getContactsByOrganisation } from '@/services/contact.service';
import { getMaintenancesByOrganisation } from '../../services/maintenance.service';
import { getSubscriptionsByOrganisation } from '../../services/subscription.service';
import { getPropositionsByOrganisation } from '../../services/proposition.service';
import { getEquipmentsByOrganisation } from '../../services/equipment.service';

export default {
  name: 'view-organisation',
  properties: ['id'],
  created() {
    // this.fetchOrganisation();
  },

  data: () => ({
    loading: false,
    search: null,
    organisation: {
      societyType: {},
      categorie: {},
      owner: {},
      form: {},
      origin: {},
      family: null,
      subfamily: null,
    },
    dialogNew: false,
    dialogEdit: false,
    contacts: [],
    contactsHeaders: [
      {
        text: 'Nom',
        align: 'start',
        value: 'lastname',
      },
      {
        text: 'Prénom',
        value: 'firstname',
      },
      {
        text: 'Email',
        value: 'email',
      },
      {
        text: 'Fixe',
        value: 'phone',
      },
      {
        text: 'Mobile',
        value: 'phone_mobile',
      },
      {
        text: 'Rôle',
        value: 'role',
      },
    ],
    formationsHeaders: [
      {
        text: 'Formation',
        align: 'start',
        value: 'equipment',
      },
      {
        text: 'Niveau',
        value: 'level.name',
      },
    ],
    maintenances: [],
    maintenancesHeaders: [
      {
        text: 'URL',
        align: 'start',
        value: 'url',
      },
      {
        text: 'Date de souscription',
        value: 'subscription_date',
      },
      {
        text: 'Echéance',
        value: 'deadline',
      },
      {
        text: 'Montant',
        value: 'price',
      },
      {
        text: 'Contacts',
        value: 'contacts',
      },
    ],
    maintenancesContactsHeaders: [
      {
        text: 'Nom',
        align: 'start',
        value: 'lastname',
      },
      {
        text: 'Prénom',
        value: 'firstname',
      },
      {
        text: 'Email',
        value: 'email',
      },
      {
        text: 'Fixe',
        value: 'phone',
      },
      {
        text: 'Mobile',
        value: 'phone_mobile',
      },
      {
        text: 'Formations',
        value: 'formations',
      },
    ],
    subscriptions: [],
    subscriptionsHeaders: [
      {
        text: 'Abonnements',
        align: 'start',
        value: 'name.name',
      },
      {
        text: 'Début',
        value: 'start_date',
      },
      {
        text: 'Fin',
        value: 'deadline',
      },
      {
        text: 'Montant',
        value: 'price',
      },
      {
        text: 'Durée',
        value: 'duration.name',
      },
    ],
    propositions: [],
    propositionsHeaders: [
      {
        text: 'Type',
        align: 'start',
        value: 'type',
      },
      {
        text: 'Description',
        value: 'description',
      },
      {
        text: 'CDE',
        value: 'cde',
      },
      {
        text: 'Date',
        value: 'date',
      },
      {
        text: 'Progression',
        value: 'percent',
      },
      {
        text: 'Montant',
        value: 'price',
      },
    ],
    equipments: [],
    equipmentsHeaders: [
      {
        text: 'Nom',
        align: 'start',
        value: 'name.name',
      },
      {
        text: 'Url',
        value: 'url',
      },
      {
        text: 'Prestataire',
        value: 'service_provider.name',
      },
      {
        text: 'Début',
        value: 'start_date',
      },
      {
        text: 'Fin',
        value: 'deadline',
      },
      {
        text: 'Montant',
        value: 'price',
      },
    ],
  }),
  methods: {
    moment(date) {
      return moment(date);
    },
    getColor(percent) {
      if (!percent) {
        return 'red';
      }
      if (percent.name === 'Signé') {
        return 'green';
      }
      if (percent.name === 'Perdu') {
        return 'red';
      }
      return 'orange';
    },
    fetchOrganisation(newRoute) {
      this.loading = true;
      getOrganisation(decodeURIComponent(newRoute.params.id))
        .then((value) => {
          console.log(value);
          this.organisation = value.data;
        })
        .catch(console.error)
        .finally(() => {
          this.loading = false;
        });
      getContactsByOrganisation(decodeURIComponent(newRoute.params.id))
        .then((value) => {
          console.log(value);
          this.contacts = value.data;
        })
        .catch(console.error)
        .finally(() => {
          this.loading = false;
        });
      getMaintenancesByOrganisation(decodeURIComponent(newRoute.params.id))
        .then((value) => {
          console.log(value);
          this.maintenances = value.data;
        })
        .catch(console.error)
        .finally(() => {
          this.loading = false;
        });
      getSubscriptionsByOrganisation(decodeURIComponent(newRoute.params.id))
        .then((value) => {
          console.log(value);
          this.subscriptions = value.data;
        })
        .catch(console.error)
        .finally(() => {
          this.loading = false;
        });
      getEquipmentsByOrganisation(decodeURIComponent(newRoute.params.id))
        .then((value) => {
          console.log(value);
          this.equipments = value.data;
        })
        .catch(console.error)
        .finally(() => {
          this.loading = false;
        });
      getPropositionsByOrganisation(decodeURIComponent(newRoute.params.id))
        .then((value) => {
          console.log(value);
          this.propositions = value.data;
        })
        .catch(console.error)
        .finally(() => {
          this.loading = false;
        });
    },
    getCustomerTo(id) {
      return {
        name: 'dashboard_edit_client',
        params: {
          clientId: id,
        },
      };
    },
    deleteOrganisation(id) {
      deleteOrganisation(id)
        .then(() => {
          console.log('success!');
        })
        .catch(console.error);
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(newRoute) {
        this.dialogNew = newRoute.meta?.showDialogNew;
        this.dialogEdit = newRoute.meta?.showDialogEdit;
        this.fetchOrganisation(newRoute);
      },
    },
  },
};
</script>

<style>
  .required .v-input::after {
    content: "*";
    color: #ff0000;
    margin-left: 1rem;
  }
</style>
