import { render, staticRenderFns } from "./maintenances.vue?vue&type=template&id=f5d07b54&scoped=true&"
import script from "./maintenances.vue?vue&type=script&lang=js&"
export * from "./maintenances.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f5d07b54",
  null
  
)

export default component.exports