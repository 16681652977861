<template>
  <v-card>
    <v-app-bar dark color="#313131" flat>
      <v-card-title>
          <span class="headline">Visualisation d'une maintenance</span>
      </v-card-title>
    </v-app-bar>

    <v-card-text>
      <v-container class="mt-4">
        <v-row align="center">
          <v-col cols="4"
              class="required">
            <p><span style="font-weight: bold;">Url :</span>{{maintenance.url}}</p>
          </v-col>
          <v-col cols="4"
              class="required">
            <p>
              <span style="font-weight: bold;">Date de souscription :</span>
            {{maintenance.subscription_date}}
            </p>
          </v-col>
          <v-col cols="4"
              class="required">
            <p><span style="font-weight: bold;">Echéance :</span>
            {{maintenance.deadline}}
            </p>
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col cols="4"
              class="required">
            <p><span style="font-weight: bold;">Montant :</span>{{maintenance.price}}</p>
          </v-col>
          <v-col cols="4">
            <p><span style="font-weight: bold;" v-if="maintenance.organisation">
              Organisation :</span>
            {{ maintenance.organisation ? maintenance.organisation.name : ''}}
            </p>
          </v-col>
        </v-row>
        <v-row v-for="(contact, index) in maintenance.contacts" :key="index">
          <v-col>{{ contact.firstname }} {{ contact.lastname}}</v-col>
          <v-col>{{ contact.email }}</v-col>
          <v-col>{{ contact.phone }}</v-col>
          <v-col>{{ contact.mobile_phone }}</v-col>
        </v-row>
      </v-container>
    </v-card-text>

    <v-footer padless>
      <v-spacer />
      <v-card-actions>
        <v-btn
          color="primary"
          text
          exact
          :to="{ name: 'dashboard_maintenances' }"
        >
          Fermer
        </v-btn>
      </v-card-actions>
    </v-footer>
  </v-card>
</template>

<script>
import { getMaintenance, deleteMaintenance } from '@/services/maintenance.service';

export default {
  name: 'view-maintenance',
  created() {
    // this.fetchOrganisation();
  },

  data: () => ({
    loading: false,
    maintenance: {
      contacts: [],
    },
    dialogNew: false,
    dialogEdit: false,
  }),
  methods: {
    fetchMaintenance(newRoute) {
      this.loading = true;
      getMaintenance(decodeURIComponent(newRoute.params.id))
        .then((value) => {
          console.log(value);
          this.maintenance = value.data;
        })
        .catch(console.error)
        .finally(() => {
          this.loading = false;
        });
    },
    getMaintenanceTo(id) {
      return {
        name: 'dashboard_edit_maintenance',
        params: {
          id,
        },
      };
    },
    deleteMaintenance(id) {
      deleteMaintenance(id)
        .then(() => {
          console.log('success!');
        })
        .catch(console.error);
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(newRoute) {
        this.dialogNew = newRoute.meta?.showDialogNew;
        this.dialogEdit = newRoute.meta?.showDialogEdit;
        this.fetchMaintenance(newRoute);
      },
    },
  },
};
</script>

<style>
  .required .v-input::after {
    content: "*";
    color: #ff0000;
    margin-left: 1rem;
  }
</style>
