<template>
  <v-container>
    <v-data-table
      :headers="headers"
      :items="subscriptions"
      :search="search"
      hide-default-footer
      calculate-widths
      :loading="loading"
    >
      <template #top>
        <v-container>
          <v-row align="center">
            <v-btn
              rounded
              color="primary"
              dark
              class="mb-2"
              :to="{ name: 'dashboard_new_subscription' }"
            >
              Ajouter un abonnement
              <v-icon right>
                mdi-plus
              </v-icon>
            </v-btn>
            <v-dialog
              v-model="dialogNew"
              persistent
              scrollable
              :fullscreen="$vuetify.breakpoint.mdAndDown"
              max-width="1200px"
            >
              <router-view />
            </v-dialog>
            <v-dialog
              v-model="dialogEdit"
              persistent
              scrollable
              :fullscreen="$vuetify.breakpoint.mdAndDown"
              max-width="1200px"
            >
              <router-view />
            </v-dialog>

            <v-spacer />

            <v-text-field
              v-model="search"
              label="Rechercher"
              append-icon="mdi-magnify"
              single-line
              hide-details
            />
          </v-row>
        </v-container>
      </template>

      <template #item.admin="{ item }">
        <span :class="{
          'font-weight-medium': item.admin,
          'font-weight-regular': !item.admin,
        }">
          {{ item.admin ? 'Administrateur' : 'Client' }}
        </span>
      </template>

      <template #item.subscription="{ item }">
        <router-link
          v-if="item.Subscription"
          :to="getSubscriptionTo(item.Subscription)"
        >
          Lien
        </router-link>
        <span v-else>Non</span>
      </template>

      <template #no-results>
        Aucun résultat trouvé :/
      </template>

      <template #no-data>
        Aucun abonnement n'a pu être récupéré :/
      </template>

      <template #item.actions="{ item }">
        <v-btn icon
               class="mr-2"
               :to="{ name: 'dashboard_view_subscription',
               params: { id: encodeURIComponent(item.id) }}">
          <v-icon small>mdi-eye</v-icon>
        </v-btn>
        <v-btn icon
               disabled
               class="mr-2"
               :to="{ name: 'dashboard_edit_subscription',
               params: { id: encodeURIComponent(item.id) }}">
          <v-icon small>mdi-pencil</v-icon>
        </v-btn>

        <v-btn disabled icon @click="deleteSubscription(item.id)">
          <v-icon small>mdi-delete</v-icon>
        </v-btn>
      </template>

      <template #no-results>
        Aucun résultat trouvé :/
      </template>

      <template #no-data>
        Aucun abonnement n'a pu être récupéré :/
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { getSubscriptions, deleteSubscription } from '../../services/subscription.service';

export default {
  name: 'Subscriptions',

  created() {
    this.fetchSubscriptions();
  },

  data: () => ({
    headers: [
      {
        text: 'Abonnements',
        align: 'start',
        value: 'name',
      },
      {
        text: 'Début',
        value: 'start_date',
      },
      {
        text: 'Fin',
        value: 'deadline',
      },
      {
        text: 'Montant',
        value: 'price',
      },
      {
        text: 'Actions',
        sortable: false,
        value: 'actions',
      },
    ],
    loading: false,
    search: null,
    subscriptions: [],
    dialogNew: false,
    dialogEdit: false,
  }),
  methods: {
    fetchSubscriptions() {
      this.loading = true;
      getSubscriptions()
        .then((value) => {
          this.subscriptions = value.data;
          console.log(this.subscriptions);
        })
        .catch(console.error)
        .finally(() => {
          this.loading = false;
        });
    },
    getSubscriptionTo(id) {
      return {
        name: 'dashboard_edit_subscription',
        params: {
          id,
        },
      };
    },
    deleteSubscription(id) {
      deleteSubscription(id)
        .then(() => {
          console.log('success!');
          this.fetchSubscriptions();
        })
        .catch(console.error);
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(newRoute) {
        this.dialogNew = newRoute.meta?.showDialogNew;
        this.dialogEdit = newRoute.meta?.showDialogEdit;
        this.fetchSubscriptions();
      },
    },
  },
};
</script>

<style scoped>

</style>
