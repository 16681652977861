<template>
  <v-form class="ma-2"
          ref="form"
          v-model="valid"
          lazy-validation>
    <v-dialog v-model="validationDialog"
              type="error"
              width="500">
      <v-alert type="error">
        Une erreur s'est produite.
      </v-alert>
    </v-dialog>
    <v-row>
      <v-col class="required">
        <v-row class="d-flex flex-nowrap ml-2">
          <v-autocomplete v-model="form.name"
                          :items="products.items"
                          item-text="name"
                          item-value="id"
                          :rules="[(v) => !!v || 'Le produit est requis']"
                          dense
                          outlined
                          :loading="loadingProducts"
                          required
                          :label="products.catalog.name"></v-autocomplete>
          <v-btn fab
                 x-small
                 dark
                 outlined
                 class="ma-auto ml-2 mt-3"
                 @click="editCatalog(products.catalog.id)"
                 color="black">
            <v-icon dark>
              mdi-plus
            </v-icon>
          </v-btn>
        </v-row>
        <v-dialog v-model="catalogDialog"
                  width="500">
          <CatalogForm :catalogId="selectedCatalog" v-on:closeCatalog="closeCatalog" />
        </v-dialog>
      </v-col>
      <v-col class="pt-0">
        <v-menu v-model="startDateDialog"
                :close-on-content-click="false"
                :nudge-right="0"
                transition="scale-transition"
                offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="form.start_date"
                          outlined
                          label="Début"
                          readonly
                          v-bind="attrs"
                          v-on="on"></v-text-field>
          </template>
          <v-date-picker v-model="form.start_date"
                         @input="startDateDialog = false"></v-date-picker>
        </v-menu>
      </v-col>
      <v-col class="pt-0">
        <v-menu v-model="deadlineDateDialog"
                :close-on-content-click="false"
                :nudge-right="0"
                transition="scale-transition"
                offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="form.deadline"
                          outlined
                          label="Echéance"
                          readonly
                          v-bind="attrs"
                          v-on="on"></v-text-field>
          </template>
          <v-date-picker v-model="form.deadline"
                         @input="deadlineDateDialog = false"></v-date-picker>
        </v-menu>
      </v-col>
      <v-col class="required pt-0">
        <v-text-field outlined
                      v-model.number="form.price"
                      :rules="priceRules"
                      label="Montant"
                      required></v-text-field>
      </v-col>
      <v-col class="required">
        <v-row class="d-flex flex-nowrap ml-2">
          <v-autocomplete v-model="form.duration"
                          :items="durations.items"
                          item-text="name"
                          item-value="id"
                          :rules="[v => !!v || 'La durée est requise']"
                          dense
                          outlined
                          :label="durations.catalog.name"
                          :loading="loadingDurations"></v-autocomplete>
          <v-btn fab
                 x-small
                 dark
                 outlined
                 class="ma-auto ml-2 mt-3"
                 @click="editCatalog(durations.catalog.id)"
                 color="black">
            <v-icon dark>
              mdi-plus
            </v-icon>
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { getCatalogByName } from '@/services/catalog.service';
import CatalogForm from '@/components/CatalogForm.vue';
import { mapActions } from 'vuex';

export default {
  name: 'SubscriptionForm',
  props: ['subscription'],
  components: {
    CatalogForm,
  },
  data: () => ({
    priceRules: [
      (v) => !!v || 'Le montant est requis',
      (v) => !Number.isNaN(Number(v)) || 'Le prix doit être un chiffre.',
    ],
    valid: true,
    validationDialog: false,
    deadlineDateDialog: false,
    startDateDialog: false,
    products: { catalog: { name: '' }, items: [] },
    loadingProducts: false,
    loadingDurations: false,
    durations: { catalog: { name: '' }, items: [] },
    selectedCatalog: null,
    catalogDialog: false,
  }),
  created() {
    this.fetchCatalogs();
  },
  computed: {
    form() {
      return this.subscription ? this.subscription : {
        organisation: null,
        price: null,
        name: null,
        start_date: null,
        deadline: null,
        duration: null,
      };
    },
  },
  methods: {
    fetchCatalogs() {
      this.loadingProducts = true;
      getCatalogByName('Produit').then((res) => {
        this.products = res.data;
      }).catch((err) => {
        console.log(err);
      }).finally(() => {
        this.loadingProducts = false;
      });

      this.loadingDurations = true;
      getCatalogByName('Dur%C3%A9e%20d\'abonnement').then((res) => {
        this.durations = res.data;
      }).catch((err) => {
        console.log(err);
      }).finally(() => {
        this.loadingDurations = false;
      });
    },
    editCatalog(catalogId) {
      this.catalogDialog = true;
      this.selectedCatalog = catalogId;
    },
    closeCatalog() {
      this.fetchCatalogs();
      this.catalogDialog = false;
      this.selectedCatalog = null;
    },
    validate() {
      // send to parent
      if (this.$refs.form.validate()) {
        if (this.form.name) {
          this.form.name = this.products.items.find((el) => el.id === this.form.name)
            || this.form.name;
        }
        if (this.form.duration) {
          this.form.duration = this.durations.items.find((el) => el.id === this.form.duration)
            || this.form.duration;
        }
        console.log(this.form);
        return this.form;
      }
      return null;
    },
    ...mapActions({
      register: 'auth/register',
    }),
  },
};
</script>

<style scoped>

</style>
