<template>
  <v-container>
    <v-data-table
      :headers="headers"
      :items="maintenances"
      :search="search"
      hide-default-footer
      calculate-widths
      :loading="loading"
    >
      <template #top>
        <v-container>
          <v-row align="center">
            <v-btn
              rounded
              color="primary"
              dark
              class="mb-2"
              :to="{ name: 'dashboard_new_maintenance' }"
            >
              Ajouter une maintenance
              <v-icon right>
                mdi-plus
              </v-icon>
            </v-btn>
            <v-dialog
              v-model="dialogNew"
              persistent
              scrollable
              :fullscreen="$vuetify.breakpoint.mdAndDown"
              max-width="1200px"
            >
              <router-view />
            </v-dialog>
            <v-dialog
              v-model="dialogEdit"
              persistent
              scrollable
              :fullscreen="$vuetify.breakpoint.mdAndDown"
              max-width="1200px"
            >
              <router-view />
            </v-dialog>

            <v-spacer />

            <v-text-field
              v-model="search"
              label="Rechercher"
              append-icon="mdi-magnify"
              single-line
              hide-details
            />
          </v-row>
        </v-container>
      </template>

      <template #item.admin="{ item }">
        <span :class="{
          'font-weight-medium': item.admin,
          'font-weight-regular': !item.admin,
        }">
          {{ item.admin ? 'Administrateur' : 'Client' }}
        </span>
      </template>

      <template #item.maintenance="{ item }">
        <router-link
          v-if="item.Maintenance"
          :to="getMaintenaceTo(item.Maintenance)"
        >
          Lien
        </router-link>
        <span v-else>Non</span>
      </template>

      <template #no-results>
        Aucun résultat trouvé :/
      </template>

      <template #no-data>
        Aucune donnée de maintenances n'a pu être récupérée :/
      </template>

      <template #item.actions="{ item }">
        <v-btn icon
               class="mr-2"
               :to="{ name: 'dashboard_view_maintenance',
               params: { id: encodeURIComponent(item.id) }}">
          <v-icon small>mdi-eye</v-icon>
        </v-btn>
        <v-btn icon
               class="mr-2"
               :to="{ name: 'dashboard_edit_maintenance',
               params: { id: encodeURIComponent(item.id) }}">
          <v-icon small>mdi-pencil</v-icon>
        </v-btn>

        <v-btn icon @click="deleteMaintenance(item.id)">
          <v-icon small>mdi-delete</v-icon>
        </v-btn>
      </template>

      <template #no-results>
        Aucun résultat trouvé :/
      </template>

      <template #no-data>
        Aucune donnée de maintenances n'a pu être récupérée :/
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { getMaintenances, deleteMaintenance } from '../../services/maintenance.service';

export default {
  name: 'Maintenances',

  created() {
    this.fetchMaintenances();
  },

  data: () => ({
    headers: [
      {
        text: 'URL',
        align: 'start',
        value: 'url',
      },
      {
        text: 'Date de souscription',
        value: 'subscription_date',
      },
      {
        text: 'Echéance',
        value: 'deadline',
      },
      {
        text: 'Montant',
        value: 'price',
      },
      {
        text: 'Actions',
        sortable: false,
        value: 'actions',
      },
    ],
    loading: false,
    search: null,
    maintenances: [],
    dialogNew: false,
    dialogEdit: false,
  }),
  methods: {
    fetchMaintenances() {
      this.loading = true;
      getMaintenances()
        .then((value) => {
          this.maintenances = value.data;
          console.log(this.maintenances);
        })
        .catch(console.error)
        .finally(() => {
          this.loading = false;
        });
    },
    getMaintenanceTo(id) {
      return {
        name: 'dashboard_edit_maintenance',
        params: {
          id,
        },
      };
    },
    deleteMaintenance(id) {
      deleteMaintenance(id)
        .then(() => {
          console.log('success!');
          this.fetchMaintenances();
        })
        .catch(console.error);
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(newRoute) {
        this.dialogNew = newRoute.meta?.showDialogNew;
        this.dialogEdit = newRoute.meta?.showDialogEdit;
        this.fetchMaintenances();
      },
    },
  },
};
</script>

<style scoped>

</style>
