<template>
  <v-card>
    <v-app-bar dark color="#313131" flat>
      <v-card-title>
          <span class="headline">
            Édition du client #{{ $route.params.clientId }}
            <span v-if="client"> ({{ customerName }})</span>
          </span>
      </v-card-title>
    </v-app-bar>

    <v-card-text v-if="client">
      <v-container class="mt-4">
        <v-row align="center" class="py-4">
          Nom du client : {{ customerName }}
        </v-row>
        <v-row class="required">
          <v-file-input
            accept="image/png, image/jpeg, image/bmp"
            prepend-icon="mdi-camera"
            label="Logo du client"
            v-model="client.logo" />
        </v-row>
        <v-alert type="error" v-if="errors.logo && errors.logo.length > 0">
          {{ errors.logo }}
        </v-alert>
      </v-container>
      <v-divider />
      <base-client-form
        v-model="client"
        v-bind:errors="errors"
        ref="form"
        @change="onFilesChange"
      />
      <v-divider />
      <v-container>
        <DocumentList v-model="client.documents">
          <template #actions="{ file }">
            <v-row>
              <v-btn
                icon
                class="mr-2"
                :href="getFilePathHref(file)"
                target="_blank"
              >
                <v-icon>mdi-eye</v-icon>
              </v-btn>
              <v-btn icon>
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </v-row>
          </template>
        </DocumentList>
      </v-container>
    </v-card-text>

    <v-footer padless>
      <v-spacer />
      <v-card-actions>
        <v-btn
          color="primary"
          text
          exact
          :to="{ name: 'dashboard_home' }"
        >
          Annuler
        </v-btn>
        <v-btn
          color="primary"
          text
          exact
          :loading="loading"
          @click="updateItem"
        >
          Enregistrer
        </v-btn>
      </v-card-actions>
    </v-footer>
  </v-card>
</template>

<script>
import BaseClientForm from '@/components/BaseClientForm.vue';
import { getCustomer, updateCustomer } from '@/services/admin.service';

import DocumentList from '../../components/DocumentList.vue';

export default {
  name: 'edit-client',
  components: {
    BaseClientForm,
    DocumentList,
  },

  created() {
    this.fetchCustomer();
  },

  data: () => ({
    client: null,
    loading: false,
    documentFiles: [],
    errors: {},
  }),
  computed: {
    customerName() {
      return this.client?.name;
    },
  },
  methods: {
    validateForm() {
      let valid = true;

      // required logo
      if (!this.client.logo || (this.client.logo.length <= 0 && !this.client.logo.existing)) {
        valid = false;
        this.$set(this.errors, 'logo', 'Ce champs est requis.');
      } else {
        this.$set(this.errors, 'logo', '');
      }

      // required host
      if (!this.client.hosting.host || this.client.hosting.host.length <= 0) {
        valid = false;
        this.$set(this.errors, 'host', 'Ce champs est requis.');
      } else {
        this.$set(this.errors, 'host', '');
      }

      // required domain
      this.client.domains.forEach((domain) => {
        if (!domain.name || domain.name.length <= 0) {
          valid = false;
          this.$set(this.errors, 'domain', 'Ce champs est requis.');
        } else {
          this.$set(this.errors, 'domain', '');
        }
      });
      console.log(this.errors);
      return valid;
    },
    generateFormData() {
      console.log(this.client);
      const res = new FormData();
      res.append('name', this.client.name);
      if (!this.client.logo.existing) {
        res.append('logo', this.client.logo);
      }
      res.append('hosting.host', this.client.hosting.host);
      res.append('hosting.from', this.client.hosting.from);
      res.append('hosting.until', this.client.hosting.until);
      res.append('maintenance.until', this.client.maintenance.until);
      // Should it be sent separately?
      // res.append('email', this.client.mail);
      this.client.domains.forEach((domain, i) => {
        res.append(`domains.${i}.name`, domain.name);
        res.append(`domains.${i}.from`, domain.from);
        res.append(`domains.${i}.until`, domain.until);
      });
      this.client.documents.forEach((document, i) => {
        res.append(`documents.${i}.name`, document.file.name);
        res.append(`documents.${i}.category`, document.category);
      });
      this.documentFiles.forEach((documentFile) => {
        res.append('documents', documentFile);
      });
      return res;
    },
    updateItem() {
      this.loading = true;
      if (!this.validateForm()) {
        console.log('not valid');
        this.loading = false;
        return;
      }

      const finalData = this.generateFormData();
      finalData.forEach((el, key) => console.log(`${key} : ${el}`));

      updateCustomer(this.$route.params.clientId, finalData)
        .then((value) => {
          if (value.status !== 200) {
            // could potentially add details about the error here
            throw new Error('Error in request!');
          }
        })
        .catch((err) => {
          // eslint-disable-next-line no-alert
          alert(`${err}, data could not be sent!`);
        })
        .finally(() => {
          this.loading = false;
          this.$router.push({ name: 'dashboard_home' });
        });
    },
    fetchCustomer() {
      this.loading = true;
      getCustomer(this.$route.params.clientId)
        .then((value) => {
          this.client = value.data;
          const vm = this;
          this.client.documents.forEach((el) => {
            vm.documentFiles.push(new File([''], `${el.file.name}.${el.file.path.split('.').pop()}`));
            vm.documentFiles[vm.documentFiles.length - 1].existing = true;
          });
          this.client.logo = new File([''], `${this.client.logo.file.name}.${this.client.logo.file.path.split('.').pop()}`);
          this.client.logo.existing = true;
          console.log(this.client);
          if (!this.client.maintenance) {
            this.client.maintenance = {
              until: '',
            };
          }
        })
        .catch(console.error)
        .finally(() => {
          this.loading = false;
        });
    },
    getFilePathHref(file) {
      return process.env.VUE_APP_API_URL + file.file.path.substr(1);
    },
    onFilesChange() {
      this.$refs.form.files.forEach((el, key) => {
        console.log(key);
        console.log(el);
        const id = parseInt(key.split('-').pop(), 10);
        console.log(id);
        if (id < this.documentFiles.length) {
          this.documentFiles[id] = el;
          this.documentFiles[id].existing = false;
        } else {
          this.documentFiles.push(el);
        }
      });
      console.log(this.$refs.form.files);
      console.log(this.documentFiles);
    },
  },
};
</script>

<style>
  .required .v-input::after {
    content: "*";
    color: #ff0000;
    margin-left: 1rem;
  }
</style>
