<template>
  <v-card>
    <v-app-bar dark color="#313131" flat>
      <v-card-title>
          <span class="headline">Création d'un catalogue</span>
      </v-card-title>
    </v-app-bar>

    <v-card-text>
      <v-container class="mt-4">
        <v-form class="ma-2"
            ref="form"
            v-model="valid"
            lazy-validation>
        <v-row align="center">
          <v-col cols="4"
              class="required">
            <v-text-field v-model="form.name"
              outlined
              :rules="nameRules"
              label="Nom"
              placeholder="Informatique"
              required>
            </v-text-field>
          </v-col>
        </v-row>
        </v-form>
      </v-container>
    </v-card-text>

    <v-footer padless>
      <v-spacer />
      <v-card-actions>
        <v-btn
          color="primary"
          text
          exact
          :to="{ name: 'dashboard_catalogs' }"
        >
          Annuler
        </v-btn>
        <v-btn
          color="primary"
          text
          exact
          @click="validate"
        >
          Enregistrer
        </v-btn>
      </v-card-actions>
    </v-footer>
  </v-card>
</template>

<script>
import { createCatalog } from '@/services/catalog.service';
import { required } from 'vuelidate/lib/validators';
import { mapActions } from 'vuex';

export default {
  name: 'Catalog',
  validations: {
    form: {
      name: {
        required,
      },
    },
  },

  data: () => ({
    newCatalog: {
      date: new Date().toISOString().substr(0, 10),
    },
    form: {
      name: '',
    },
    valid: true,
    nameRules: [
      (v) => !!v || 'Le nom est requis',
    ],
  }),
  methods: {
    validate() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$refs.form.validate();
      } else {
        createCatalog(this.form).then(() => {
          this.$router.push({ name: 'dashboard_catalogs' });
        });
      }
    },

    ...mapActions({
      register: 'auth/register',
    }),
  },
};
</script>

<style>
  .required .v-input::after {
    content: "*";
    color: #ff0000;
    margin-left: 1rem;
  }
</style>
