<template>
  <v-card>
    <v-app-bar dark color="#313131" flat>
      <v-card-title>
          <span class="headline">Visualisation du catalogue : {{ catalog.name }}</span>
      </v-card-title>
    </v-app-bar>

    <v-card-text>
      <v-container class="mt-4">
        <h2 class="mb-2">Items</h2>
        <v-row align="center" v-for="item in items" :key="item.id">
          <v-col cols="4"
                 class="required">
            <p>{{item.name}}</p>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>

    <v-footer padless>
      <v-spacer />
      <v-card-actions>
        <v-btn
          color="primary"
          text
          exact
          :to="{ name: 'dashboard_catalogs' }"
        >
          Fermer
        </v-btn>
      </v-card-actions>
    </v-footer>
  </v-card>
</template>

<script>
// import { getCatalogs } from '@/services/admin.service';
import { getCatalog, deleteCatalog } from '@/services/catalog.service';

export default {
  name: 'Catalogs',
  properties: ['id'],
  created() {
    // this.fetchCatalogs();
  },

  data: () => ({
    loading: false,
    search: null,
    dialogNew: false,
    dialogEdit: false,
    catalog: {},
    items: [],
  }),
  methods: {
    fetchCatalogs(newRoute) {
      this.loading = true;
      getCatalog(decodeURIComponent(newRoute.params.id))
        .then((value) => {
          console.log(value.data);
          this.catalog = value.data.catalog;
          this.items = value.data.items;
        })
        .catch(console.error)
        .finally(() => {
          this.loading = false;
        });
    },
    getCustomerTo(id) {
      return {
        name: 'dashboard_edit_client',
        params: {
          clientId: id,
        },
      };
    },
    deleteCatalog(id) {
      deleteCatalog(id)
        .then(() => {
          console.log('success!');
        })
        .catch(console.error);
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(newRoute) {
        this.dialogNew = newRoute.meta?.showDialogNew;
        this.dialogEdit = newRoute.meta?.showDialogEdit;
        this.fetchCatalogs(newRoute);
      },
    },
  },
};
</script>

<style>
  .required .v-input::after {
    content: "*";
    color: #ff0000;
    margin-left: 1rem;
  }
</style>
