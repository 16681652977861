<template>
  <v-skeleton-loader boilerplate type="date-picker" />
</template>

<script>
export default {
  name: 'Settings',

  data: () => ({
    //
  }),
};
</script>

<style scoped>

</style>
