<template>
  <v-card>
    <v-app-bar dark color="#313131" flat>
      <v-card-title>
          <span class="headline">Édition de l'équipement {{ form.name || '' }}</span>
      </v-card-title>
    </v-app-bar>

    <v-card-text>
      <v-container class="mt-4">
        <v-form class="ma-2"
                ref="form"
                v-model="valid"
                lazy-validation>
        <v-row>
            <v-col>
              <v-text-field outlined
                            v-model.number="form.name"
                            :rules="nameRules"
                            label="Nom"
                            v-model.trim="$v.form.name.$model"
                            required></v-text-field>
            </v-col>
            <v-col class="required">
              <v-text-field outlined
                            v-model.number="form.service_provider"
                            :rules="service_providerRules"
                            label="Prestataire"
                            v-model.trim="$v.form.service_provider.$model"
                            required></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field outlined
                            v-model.number="form.price"
                            :rules="priceRules"
                            label="Montant"
                            v-model.trim="$v.form.price.$model"
                            required></v-text-field>
            </v-col>
            <v-col class="required">
              <v-select v-model="form.organisation"
                        :items="organisations"
                        item-text="name"
                        item-value="id"
                        persistent-hint
                        outlined
                        :rules="[v => !!v || 'L\'organisation est requise']"
                        v-model.trim="$v.form.organisation.$model"
                        label="Organisation"
                        :loading="loadingOrganisations"
                        required></v-select>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="4"
                   class="required">
              <v-text-field v-model="form.url"
                            v-model.trim="$v.form.url.$model"
                            outlined
                            :rules="[v => !!v || 'L\'url est requise']"
                            label="Url"
                            required>
              </v-text-field>
            </v-col>
            <v-col>
              <v-menu v-model="startDateDialog"
                      :close-on-content-click="false"
                      :nudge-right="0"
                      transition="scale-transition"
                      offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="form.start_date"
                                outlined
                                label="Date de souscription"
                                readonly
                                v-bind="attrs"
                                v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="form.start_date"
                               @input="startDateDialog = false"></v-date-picker>
              </v-menu>
            </v-col>
            <v-col>
              <v-menu v-model="deadlineDateDialog"
                      :close-on-content-click="false"
                      :nudge-right="0"
                      transition="scale-transition"
                      offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="form.deadline"
                                outlined
                                label="Echéance"
                                readonly
                                v-bind="attrs"
                                v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="form.deadline"
                               @input="deadlineDateDialog = false"></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card-text>

    <v-footer padless>
      <v-spacer />
      <v-card-actions>
        <v-btn
          color="primary"
          text
          exact
          :to="{ name: 'dashboard_equipments' }"
        >
          Annuler
        </v-btn>
        <v-btn
          color="primary"
          text
          exact
          @click="validate"
        >
          Enregistrer
        </v-btn>
      </v-card-actions>
    </v-footer>
  </v-card>
</template>

<script>
import { required, numeric } from 'vuelidate/lib/validators';
import { getOrganisations } from '../../services/organisation.service';
import { getEquipment, updateEquipment } from '../../services/equipment.service';

export default {
  name: 'edit-equipment',
  validations: {
    form: {
      name: {
        required,
      },
      service_provider: {
        required,
      },
      price: {
        required,
        numeric,
      },
      organisation: {
        required,
      },
      url: {
        required,
      },
    },
  },

  data: () => ({
    priceRules: [
      (v) => !!v || 'Le montant est requis',
      (v) => Number.isInteger(Number(v)) || 'The value must be an integer number',
    ],
    service_providerRules: [
    ],
    nameRules: [
    ],
    form: {
      organisation: null,
      price: null,
      name: null,
      service_provider: null,
      url: null,
      start_date: new Date().toISOString().substr(0, 10),
      deadline: new Date().toISOString().substr(0, 10),
    },
    valid: true,
    startDateDialog: false,
    deadlineDateDialog: false,
    loadingCategories: false,
    organisations: [],
    loadingOrganisations: false,
    loadingContacts: false,
  }),
  methods: {
    async fetchEquipment(newRoute) {
      this.loadingOrganisations = true;
      getOrganisations().then((res) => {
        this.organisations = res.data;
      }).catch((err) => {
        console.log(err);
      }).finally(() => {
        this.loadingOrganisations = false;
      });

      getEquipment(decodeURIComponent(newRoute.params.id)).then((value) => {
        this.form = value.data;
        this.form.start_date = new Date(this.form.start_date)
          .toISOString().substr(0, 10);
        this.form.deadline = new Date(this.form.deadline).toISOString().substr(0, 10);
      })
        .catch((err) => console.log(err));
    },
    validate() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$refs.form.validate();
      } else {
        updateEquipment(this.form.id, this.form).then(() => {
          this.$router.push({ name: 'dashboard_equipments' });
        });
      }
    },
  },
  watch: {
    $route: {
      handler: 'fetchEquipment',
      immediate: true,
    },
  },
};
</script>

<style>
  .required .v-input::after {
    content: "*";
    color: #ff0000;
    margin-left: 1rem;
  }
</style>
