import axios from 'axios';
import authHeader from './auth-header';

// get all contacts with pagination
export function getContacts() {
  return axios.get(`${process.env.VUE_APP_API_URL}contacts/`, { headers: authHeader() });
}

export function getContact(id) {
  return axios.get(`${process.env.VUE_APP_API_URL}contacts/${encodeURIComponent(id)}`, { headers: authHeader() });
}

export function getContactsByOrganisation(id) {
  return axios.get(`${process.env.VUE_APP_API_URL}contacts/organisation/${encodeURIComponent(id)}`, { headers: authHeader() });
}

export function createContact(data) {
  return axios.post(`${process.env.VUE_APP_API_URL}contacts`, data, { headers: authHeader() });
}

export function updateContact(id, data) {
  return axios.patch(`${process.env.VUE_APP_API_URL}contacts/${encodeURIComponent(id)}`, data, { headers: authHeader() });
}

export function deleteContact(id) {
  return axios.delete(`${process.env.VUE_APP_API_URL}contacts/${id}`, { headers: authHeader() });
}
