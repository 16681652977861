<template>
  <v-card>
    <v-app-bar dark color="#313131" flat>
      <v-card-title>
          <span class="headline">Création d'un contact</span>
      </v-card-title>
    </v-app-bar>
    <v-dialog v-model="validationDialog"
              type="error"
              width="500">
      <v-alert type="error">
        Certains champs sont invalides.
      </v-alert>
    </v-dialog>

    <v-card-text>
      <v-container class="mt-4">
        <ContactForm ref="form" />
      </v-container>
    </v-card-text>

    <v-footer padless>
      <v-spacer />
      <v-card-actions>
        <v-btn
          color="primary"
          text
          exact
          :to="{ name: 'dashboard_contacts' }"
        >
          Annuler
        </v-btn>
        <v-btn
          color="primary"
          text
          exact
          @click="validate"
        >
          Enregistrer
        </v-btn>
      </v-card-actions>
    </v-footer>
  </v-card>
</template>

<script>
import { createContact } from '@/services/contact.service';
import ContactForm from '@/components/ContactForm.vue';
import { mapActions } from 'vuex';

export default {
  name: 'Contact',
  components: {
    ContactForm,
  },

  data: () => ({
    validationDialog: false,
  }),
  methods: {
    validate() {
      if (this.$refs.form) {
        const valid = this.$refs.form.validate();
        if (valid) {
          createContact(valid).then(() => {
            this.$router.push({ name: 'dashboard_contacts' });
          });
        }
      }
    },

    ...mapActions({
      register: 'auth/register',
    }),
  },
};
</script>

<style>
  .required .v-input::after {
    content: "*";
    color: #ff0000;
    margin-left: 1rem;
  }
</style>
