<template>
  <v-card>
    <v-app-bar dark color="#313131" flat>
      <v-card-title>
          <span class="headline">Édition de l'utilisateur {{ user.name || '' }}</span>
      </v-card-title>
    </v-app-bar>

    <v-card-text>
      <v-container class="mt-4">
        <v-row align="center">
          <v-col cols="4"
              class="required">
            <v-text-field
              v-model="user.name"
              outlined
              color="purple"
              label="Nom"
              placeholder="John Doe"
            />
            <v-alert
              type="error"
              v-if="errors.name && errors.name.length > 0">
            {{ errors.name }}
          </v-alert>
          </v-col>
          <v-col cols="4"
              class="required">
            <v-text-field
              v-model="user.email"
              outlined
              color="orange"
              type="email"
              label="Email"
              placeholder="john_doe@exemple.com"
              readonly
            />
            <v-alert
              type="error"
              v-if="errors.email && errors.email.length > 0">
            {{ errors.email }}
          </v-alert>
          </v-col>
          <v-col cols="4"
              class="required">
            <v-text-field
              v-model="user.password"
              @input="confirmPass = true"
              outlined
              color="cyan"
              type="password"
              label="Mot de passe"
              placeholder="Veuillez saisir un mot de passe..."
            />
            <v-alert
              type="error"
              v-if="errors.password && errors.password.length > 0">
            {{ errors.password }}
          </v-alert>
          </v-col>
          <v-col v-if="confirmPass" cols="8"
              class="required"><v-spacer /></v-col>
          <v-col v-if="confirmPass" cols="4"
              class="required">
            <v-text-field
              v-model="user.cPassword"
              outlined
              color="cyan"
              type="password"
              label="Confirmation du mot de passe"
              placeholder="Veuillez confirmer le mot de passe..."
            />
          </v-col>
        </v-row>
        <v-divider />
        <v-row class="py-12">
          <v-col cols="3" class="text-center">
            <span class="text-body-1">
              Compte
              <span :class="{
                'font-weight-bold': true,
                'red--text': user.admin
              }">
                {{ userType }}
              </span>
            </span>
            <v-switch
              v-model="user.admin"
              color="red"
              label="Admin"
              readonly
            />
          </v-col>
          <v-spacer />
          <v-divider inset vertical role="presentation" />
          <v-spacer />
          <v-col cols="8">
            <v-select
              v-model="user.Customer"
              label="Lier un compte client"
              :disabled="Boolean(user.admin)"
              :items="clients"
              hint="Le compte client aura accès à ce client sur le front"
              persistent-hint
              outlined
            >
              <template #no-data class="pa-4">
                Aucun client n'a pu être récupéré !
              </template>
            </v-select>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>

    <v-footer padless>
      <v-spacer />
      <v-card-actions>
        <v-btn
          color="primary"
          text
          exact
          :to="{ name: 'dashboard_users' }"
        >
          Annuler
        </v-btn>
        <v-btn
          color="primary"
          text
          exact
          @click="editItem"
        >
          Enregistrer
        </v-btn>
      </v-card-actions>
    </v-footer>
  </v-card>
</template>

<script>
import { getUser, updateUser } from '../../services/user.service';
import { getCustomers } from '../../services/admin.service';

export default {
  name: 'edit-user',
  data: () => ({
    user: {
      name: '',
      email: '',
      password: '',
      cPassword: '',
      admin: false,
      Customer: '',
    },
    loading: false,
    confirmPass: false,
    customers: [],
    errors: {},
  }),
  methods: {
    validateForm() {
      let valid = true;
      // const re = /[^@\t\r\n]+@[^@\t\r\n]+\.[^@\t\r\n]+/;
      // required name
      if (!this.user.name || this.user.name.length <= 0) {
        valid = false;
        this.$set(this.errors, 'name', 'Ce champs est requis.');
      } else {
        this.$set(this.errors, 'name', '');
      }

      // required password
      if (this.user.password !== this.user.cPassword) {
        valid = false;
        this.$set(this.errors, 'password', 'Les mots de passe doivent correspondre.');
      } else {
        this.$set(this.errors, 'password', '');
      }

      console.log(this.errors);
      return valid;
    },
    editItem() {
      if (!this.validateForm()) {
        console.log('not valid');
        return;
      }
      const { email, ...data } = this.user;
      updateUser(email, data);
      this.$router.push({ name: 'dashboard_users' });
    },
    fetchCustomers(newRoute) {
      getCustomers()
        .then((value) => {
          this.customers = value.data;
        })
        .catch(console.error);
      getUser(decodeURIComponent(newRoute.params.email))
        .then((value) => {
          this.user = value.data;
        })
        .catch(console.error);
    },
  },
  computed: {
    userType() {
      return this.user.admin
        ? 'administrateur'
        : 'client';
    },
    clients() {
      return this.customers.map((client) => ({ value: client.id, text: client.name }));
    },
  },
  watch: {
    $route: {
      handler: 'fetchCustomers',
      immediate: true,
    },
  },
};
</script>

<style>
  .required .v-input::after {
    content: "*";
    color: #ff0000;
    margin-left: 1rem;
  }
</style>
