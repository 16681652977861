<template>
  <v-card>
    <v-app-bar dark color="#313131" flat>
      <v-card-title>
          <span class="headline">Édition du catalog {{ form.catalog.name || '' }}</span>
      </v-card-title>
    </v-app-bar>

    <v-card-text>
      <v-container class="mt-4">
        <v-form class="ma-2"
                ref="form"
                v-model="valid"
                lazy-validation>
          <h2 class="mb-2">Items</h2>
          <v-row>
            <v-text-field v-model="newItem"
                          outlined
                          :rules="[v => !!v || 'Un nom est requis']"
                          label="Nom"
                          required>
            </v-text-field>
            <v-btn @click="addItem" class="mb-2">Ajouter l'item</v-btn>
          </v-row>
          <v-container>
            <v-row v-for="(item, index) in form.items" :key="index">
              <v-col class="required">
                <v-text-field v-model="item.name"
                              outlined
                              :rules="[v => !!v || 'Un nom est requis']"
                              label="Nom"
                              required>
                </v-text-field>
              </v-col>
              <v-col>
                <v-btn icon @click="updateItem(index)"><v-icon>mdi-pencil</v-icon></v-btn>
                <v-btn icon @click="deleteItem(index)"><v-icon>mdi-delete</v-icon></v-btn>
              </v-col>
            </v-row>
            </v-container>
        </v-form>
      </v-container>
    </v-card-text>

    <v-footer padless>
      <v-spacer />
      <v-card-actions>
        <v-btn
          color="primary"
          text
          exact
          :to="{ name: 'dashboard_catalogs' }"
        >
          Fermer
        </v-btn>
      </v-card-actions>
    </v-footer>
  </v-card>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import {
  getCatalog, createCatalogItem, updateCatalogItem, deleteCatalogItem,
} from '../../services/catalog.service';

export default {
  name: 'edit-catalog',
  validations: {
    form: {
      catalog: {
        name: {
          required,
        },
      },
    },
  },

  data: () => ({
    form: {
      catalog: null,
      items: [],
    },
    newItem: null,
    valid: true,
  }),
  methods: {
    async fetchCatalog(newRoute) {
      getCatalog(decodeURIComponent(newRoute.params.id)).then((value) => {
        this.form = value.data;
      })
        .catch((err) => console.log(err));
    },
    addItem() {
      if (this.newItem && this.newItem.length > 0 && this.form.catalog.id) {
        createCatalogItem({ name: this.newItem, catalogId: this.form.catalog.id }).then(() => {
          this.newItem = null;
          getCatalog(this.form.catalog.id).then((value) => {
            this.form = value.data;
          })
            .catch((err) => console.log(err));
        });
      }
    },
    updateItem(id) {
      if (this.form.catalog.id) {
        updateCatalogItem(this.form.items[id].id, this.form.items[id]).then(() => {
          getCatalog(this.form.catalog.id).then((value) => {
            this.form = value.data;
          })
            .catch((err) => console.log(err));
        });
      }
    },
    deleteItem(id) {
      if (this.form.catalog.id) {
        deleteCatalogItem(this.form.items[id].id).then(() => {
          getCatalog(this.form.catalog.id).then((value) => {
            this.form = value.data;
          })
            .catch((err) => console.log(err));
        });
      }
    },
  },
  watch: {
    $route: {
      handler: 'fetchCatalog',
      immediate: true,
    },
  },
};
</script>

<style>
  .required .v-input::after {
    content: "*";
    color: #ff0000;
    margin-left: 1rem;
  }
</style>
