<template>
  <v-card>
    <v-app-bar dark color="#313131" flat>
      <v-card-title>
          <span class="headline">Création d'une organisation</span>
      </v-card-title>
    </v-app-bar>
    <v-dialog v-model="validationDialog"
              type="error"
              width="500">
      <v-alert type="error">
        Certains champs sont invalides.
      </v-alert>
    </v-dialog>

    <v-card-text>
      <v-container class="mt-4">
        <OrganisationForm ref="formOrganisation" />
        <v-divider />
        <v-row class="ma-2 ml-0 d-flex align-center">
          <h2 class="mb-8">Contacts</h2>
          <v-btn small outlined @click="addContact" class="ml-2 mb-8">
            <v-icon dark>
              mdi-plus
            </v-icon>
            <span class="d-sr-only">Ajouter un contact</span>
          </v-btn>
          <p class="ma-2 mb-10">OU</p>
          <v-col cols="4">
            <v-autocomplete v-model="selectedContact"
                            :items="contacts"
                            item-text="lastname"
                            item-value="id"
                            dense
                            outlined
                            :loading="loadingContacts"
                            label="Sélectionner un contact"></v-autocomplete>
          </v-col>
          <v-btn small outlined @click="addSelectedContact" class="ml-2 mb-8">
            <v-icon dark>
              mdi-plus
            </v-icon>
            <span class="d-sr-only">Ajouter un contact</span>
          </v-btn>
        </v-row>
        <v-container>
          <v-row v-for="(item, id) in formContacts" :key="id">
            <v-col>
              <ContactForm ref="formContacts"
                           :isEditingContact="item"
                           :isInsideOrganisation="true"
                           v-if="isEditingContact === id" />
              <v-container v-else>
                <v-row style="border-bottom:solid 1px grey;" class="mb-4">
                  <v-col cols="2">{{item.lastname}}</v-col>
                  <v-col cols="2">{{item.firstname}}</v-col>
                  <v-col cols="2">{{item.email}}</v-col>
                  <v-col cols="2">{{item.phone}}</v-col>
                  <v-col cols="2">{{item.phone_mobile}}</v-col>
                  <v-col cols="2">{{item.role}}</v-col>
                </v-row>
              </v-container>
            </v-col>
            <v-col cols="2">
              <v-btn icon @click="editContact(id)" v-if="isEditingContact !== id">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn icon @click="stopEditingContact(id)" v-else>
                <v-icon>mdi-check</v-icon>
              </v-btn>
              <v-btn icon @click="deleteContact(id)"><v-icon>mdi-delete</v-icon></v-btn>
            </v-col>
          </v-row>
        </v-container>
        <v-divider />
        <v-row class="ma-2 ml-0 d-flex align-center">
          <h2>Maintenances</h2>
          <v-btn small outlined @click="addNewMaintenance" class="ml-2">
            <v-icon dark>
              mdi-plus
            </v-icon>
            <span class="d-sr-only">Ajouter une maintenance</span>
          </v-btn>
        </v-row>
        <v-container>
          <v-row v-for="(item, id) in formMaintenances" :key="id">
            <v-col>
              <MaintenanceForm ref="formMaintenances"
                               :maintenance="item"
                               v-if="isEditingMaintenance === id" />
              <v-container v-else>
                <v-row style="border-bottom:solid 1px grey;" class="mb-4">
                  <v-col cols="2">{{item.product.name}}</v-col>
                  <v-col cols="2">{{item.url}}</v-col>
                  <v-col cols="2">
                    <v-chip color="grey"
                            text-color="white"
                            dark>
                      {{item.subscription_date}}
                    </v-chip>
                  </v-col>
                  <v-col cols="2">
                    <v-chip :color="new Date(item.deadline) <= (new Date())
                            .setHours(0,0,0,0) ? 'red' : 'green'"
                            text-color="white"
                            dark>
                      {{item.deadline}}
                    </v-chip>
                  </v-col>
                  <v-col cols="2">{{item.price}} €</v-col>
                </v-row>
              </v-container>
            </v-col>
            <v-col cols="2">
              <v-btn icon @click="editMaintenance(id)" v-if="isEditingMaintenance !== id">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn icon @click="stopEditingMaintenance(id)" v-else>
                <v-icon>mdi-check</v-icon>
              </v-btn>
              <v-btn icon @click="deleteMaintenance(id)"><v-icon>mdi-delete</v-icon></v-btn>
            </v-col>
          </v-row>
        </v-container>
        <v-divider />
        <v-row class="ma-2 ml-0 d-flex align-center">
          <h2>Abonnements</h2>
          <v-btn small outlined @click="addSubscription" class="ml-2">
            <v-icon dark>
              mdi-plus
            </v-icon>
            <span class="d-sr-only">Ajouter un abonnement</span>
          </v-btn>
        </v-row>
        <v-container>
          <v-row v-for="(item, id) in formSubscriptions" :key="id">
            <v-col>
              <SubscriptionForm ref="formSubscriptions"
                                :subscription="item"
                                v-if="isEditingSubscription === id" />
              <v-container v-else>
                <v-row style="border-bottom:solid 1px grey;" class="mb-4">
                  <v-col cols="2">{{item.name.name}}</v-col>
                  <v-col cols="2">
                    <v-chip color="grey"
                            text-color="white"
                            dark>
                      {{item.start_date}}
                    </v-chip>
                  </v-col>
                  <v-col cols="2">
                    <v-chip :color="new Date(item.deadline) <= (new Date())
                            .setHours(0,0,0,0) ? 'red' : 'green'"
                            text-color="white"
                            dark>
                      {{item.deadline}}
                    </v-chip>
                  </v-col>
                  <v-col cols="2">{{item.price}} €</v-col>
                  <v-col cols="2">{{item.duration.name}}</v-col>
                </v-row>
              </v-container>
            </v-col>
            <v-col cols="2">
              <v-btn icon @click="editSubscription(id)" v-if="isEditingSubscription !== id">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn icon @click="stopEditingSubscription(id)" v-else>
                <v-icon>mdi-check</v-icon>
              </v-btn>
              <v-btn icon @click="deleteSubscription(id)"><v-icon>mdi-delete</v-icon></v-btn>
            </v-col>
          </v-row>
        </v-container>
        <v-divider />
        <v-row class="ma-2 ml-0 d-flex align-center">
          <h2>Propositions</h2>
          <v-btn small outlined @click="addProposition" class="ml-2">
            <v-icon dark>
              mdi-plus
            </v-icon>
            <span class="d-sr-only">Ajouter une proposition</span>
          </v-btn>
        </v-row>
        <v-container>
          <v-row v-for="(item, id) in formPropositions" :key="id">
            <v-col>
              <PropositionForm ref="formPropositions"
                               :proposition="item"
                               v-if="isEditingProposition === id" />
              <v-container v-else>
                <v-row style="border-bottom:solid 1px grey;" class="mb-4">
                  <v-col cols="2">{{item.type}}</v-col>
                  <v-col cols="2">{{item.description}}</v-col>
                  <v-col cols="2">{{item.price}} €</v-col>
                  <v-col cols="2">
                    <v-chip color="grey"
                            text-color="white"
                            dark>
                      {{item.date}}
                    </v-chip>
                  </v-col>
                  <v-col cols="2">
                    <v-chip color="grey"
                            text-color="white"
                            dark>
                      {{item.cde}}
                    </v-chip>
                  </v-col>
                  <v-col cols="2">{{ item.percent.name }}</v-col>
                </v-row>
              </v-container>
            </v-col>
            <v-col cols="2">
              <v-btn icon @click="editProposition(id)" v-if="isEditingProposition !== id">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn icon @click="stopEditingProposition(id)" v-else>
                <v-icon>mdi-check</v-icon>
              </v-btn>
              <v-btn icon @click="deleteProposition(id)"><v-icon>mdi-delete</v-icon></v-btn>
            </v-col>
          </v-row>
        </v-container>
        <v-divider />
        <v-row class="ma-2 ml-0 d-flex align-center">
          <h2>Equipements</h2>
          <v-btn small outlined @click="addEquipment" class="ml-2">
            <v-icon dark>
              mdi-plus
            </v-icon>
            <span class="d-sr-only">Ajouter un équipement</span>
          </v-btn>
        </v-row>
        <v-container>
          <v-row v-for="(item, id) in formEquipments" :key="id">
            <v-col>
              <EquipmentForm ref="formEquipments"
                             :equipment="item"
                             v-if="isEditingEquipment === id" />
              <v-container v-else>
                <v-row style="border-bottom:solid 1px grey;" class="mb-4">
                  <v-col cols="2">{{item.name.name}}</v-col>
                  <v-col cols="2">{{item.url}}</v-col>
                  <v-col cols="2">{{item.service_provider.name}}</v-col>
                  <v-col cols="2">
                    <v-chip color="grey"
                            text-color="white"
                            dark>
                      {{item.start_date}}
                    </v-chip>
                  </v-col>
                  <v-col cols="2">
                    <v-chip :color="new Date(item.deadline) <= (new Date())
                            .setHours(0,0,0,0) ? 'red' : 'green'"
                            text-color="white"
                            dark>
                      {{item.deadline}}
                    </v-chip>
                  </v-col>
                  <v-col cols="2">{{item.price}} €</v-col>
                </v-row>
              </v-container>
            </v-col>
            <v-col cols="2">
              <v-btn icon @click="editEquipment(id)" v-if="isEditingEquipment !== id">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn icon @click="stopEditingEquipment(id)" v-else>
                <v-icon>mdi-check</v-icon>
              </v-btn>
              <v-btn icon @click="deleteEquipment(id)"><v-icon>mdi-delete</v-icon></v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-container>

    </v-card-text>

    <v-footer padless>
      <v-spacer />
      <v-card-actions>
        <v-btn
          color="primary"
          text
          exact
          :to="{ name: 'dashboard_organisations' }"
        >
          Annuler
        </v-btn>
        <v-btn
          color="primary"
          text
          exact
          @click="validate"
        >
          Enregistrer
        </v-btn>
      </v-card-actions>
    </v-footer>
  </v-card>
</template>
<script>
import { getCatalogByName } from '@/services/catalog.service';
import { getOrganisations, createOrganisation } from '@/services/organisation.service';
import { getMaintenances, createMaintenance } from '@/services/maintenance.service';
import { createSubscription } from '@/services/subscription.service';
import { createProposition } from '@/services/proposition.service';
import { createEquipment } from '@/services/equipment.service';
import { getContacts, createContact } from '@/services/contact.service';
// import { deleteUser } from '@/services/user.service';
// import { defineComponent, ref, onMounted } from 'vue';
import { mapActions } from 'vuex';
import MaintenanceForm from '@/components/MaintenanceForm.vue';
import SubscriptionForm from '@/components/SubscriptionForm.vue';
import PropositionForm from '@/components/PropositionForm.vue';
import EquipmentForm from '@/components/EquipmentForm.vue';
import ContactForm from '@/components/ContactForm.vue';
import OrganisationForm from '@/components/OrganisationForm.vue';

export default {
  name: 'Organisation',
  components: {
    MaintenanceForm,
    OrganisationForm,
    SubscriptionForm,
    PropositionForm,
    EquipmentForm,
    ContactForm,
  },
  data: () => ({
    validationDialog: false,
    newOrganisation: {
      date: new Date().toISOString().substr(0, 10),
    },
    form: {
      date: new Date().toISOString().substr(0, 10),
    },
    formMaintenances: [],
    isEditingMaintenance: null,
    formSubscriptions: [],
    isEditingSubscription: null,
    formPropositions: [],
    isEditingProposition: null,
    formEquipments: [],
    isEditingEquipment: null,
    formContacts: [],
    isEditingContact: null,
    loadingMaintenances: false,
    maintenances: [],
    newMaintenance: null,
    valid: true,
    // debut 2
    dateDialog: false,
    siretDialog: false,
    searchResult: null,
    siretRules: [
      (v) => (v && v.length === 14) || 'Le numéro de SIRET doit contenir 14 caractères',
    ],
    nameRules: [
      (v) => !!v || 'Le nom est requis',
    ],
    loadingSocietyTypes: false,
    societyTypeCatalog: { catalog: { name: '' }, items: [] },
    societyTypeRules: [
    ],
    address1Rules: [
    ],
    address2Rules: [
    ],
    address3Rules: [
    ],
    postalCodeRules: [
    ],
    cityRules: [
    ],
    countryRules: [
    ],
    urlRules: [
    ],
    caRules: [
      (v) => Number.isInteger(Number(v)) || 'La valeur doit être un nombre entier',
      (v) => v >= 0 || 'La valeur doit être supérieure à zéro',
    ],
    caMcRules: [
      (v) => Number.isInteger(Number(v)) || 'La valeur doit être un nombre entier',
      (v) => v >= 0 || 'La valeur doit être supérieure à zéro',
    ],
    workforceRules: [
      (v) => Number.isInteger(Number(v)) || 'La valeur doit être un nombre entier',
      (v) => v >= 0 || 'La valeur doit être supérieure à zéro',
    ],
    apeCodeRules: [
    ],
    phoneRules: [
    ],
    groupRules: [
    ],
    notesRules: [
    ],
    // fin 2
    loadingCategories: false,
    categories: { catalog: { name: '' }, items: [] },
    loadingOwners: false,
    owners: { catalog: { name: '' }, items: [] },
    loadingOrigines: false,
    origines: { catalog: { name: '' }, items: [] },
    loadingForms: false,
    forms: { catalog: { name: '' }, items: [] },
    loadingSponsors: false,
    sponsors: [],
    loadingFamilies: false,
    families: { catalog: { name: '' }, items: [] },
    loadingSubfamilies: false,
    subfamilies: { catalog: { name: '' }, items: [] },
    contacts: [],
    selectedContact: null,
    loadingContacts: false,
  }),
  created() {
    this.fetchCatalogs();
    this.fetchContacts();
  },
  methods: {
    fetchCatalogs() {
      this.loadingCategories = true;
      getCatalogByName('Catégorie').then((res) => {
        this.categories = res.data;
      }).catch((err) => {
        console.log(err);
      }).finally(() => {
        this.loadingCategories = false;
      });

      this.loadingOwners = true;
      getCatalogByName('Owner').then((res) => {
        this.owners = res.data;
      }).catch((err) => {
        console.log(err);
      }).finally(() => {
        this.loadingOwners = false;
      });

      this.loadingOrigines = true;
      getCatalogByName('Origine').then((res) => {
        this.origines = res.data;
      }).catch((err) => {
        console.log(err);
      }).finally(() => {
        this.loadingOrigines = false;
      });

      this.loadingSocietyTypes = true;
      getCatalogByName('Modèle%20économique').then((res) => {
        this.societyTypeCatalog = res.data;
      }).catch((err) => {
        console.log(err);
      }).finally(() => {
        this.loadingSocietyTypes = false;
      });

      this.loadingForms = true;
      getCatalogByName('Forme').then((res) => {
        this.forms = res.data;
        console.log(res.data);
      }).catch((err) => {
        console.log(err);
      }).finally(() => {
        this.loadingForms = false;
      });

      this.loadingSponsors = true;
      getOrganisations().then((res) => {
        this.sponsors = res.data;
        console.log(res.data);
        console.log(res.data[0].id);
      }).catch((err) => {
        console.log(err);
      }).finally(() => {
        this.loadingSponsors = false;
      });

      this.loadingFamilies = true;
      getCatalogByName('Famille').then((res) => {
        this.families = res.data;
      }).catch((err) => {
        console.log(err);
      }).finally(() => {
        this.loadingFamilies = false;
      });

      this.loadingSubfamilies = true;
      getCatalogByName('Sous-famille').then((res) => {
        this.subfamilies = res.data;
      }).catch((err) => {
        console.log(err);
      }).finally(() => {
        this.loadingSubfamilies = false;
      });

      this.loadingMaintenances = true;
      getMaintenances().then((res) => {
        this.maintenances = res.data;
      }).catch((err) => {
        console.log(err);
      }).finally(() => {
        this.loadingMaintenances = false;
      });
    },
    fetchContacts() {
      this.loadingContacts = true;
      getContacts().then((res) => {
        this.contacts = res.data;
      }).catch((err) => {
        console.log(err);
      }).finally(() => {
        this.loadingContacts = false;
      });
    },
    validate() {
      // validate orga
      const validOrga = this.$refs.formOrganisation.validate();

      // validate maintenances
      let validMaintenances = true;
      if (this.$refs.formMaintenances && this.$refs.formMaintenances[0]) {
        const valid = this.$refs.formMaintenances[0].validate();
        if (valid) {
          this.formMaintenances[this.isEditingMaintenance] = valid;
        } else {
          validMaintenances = false;
        }
      }

      // validate subscriptions
      let validSubscriptions = true;
      if (this.$refs.formSubscriptions && this.$refs.formSubscriptions[0]) {
        const valid = this.$refs.formSubscriptions[0].validate();
        if (valid) {
          this.formSubscriptions[this.isEditingSubscription] = valid;
        } else {
          validSubscriptions = false;
        }
      }

      // validate propositions
      let validPropositions = true;
      if (this.$refs.formPropositions && this.$refs.formPropositions[0]) {
        const valid = this.$refs.formPropositions[0].validate();
        if (valid) {
          this.formPropositions[this.isEditingProposition] = valid;
        } else {
          validPropositions = false;
        }
      }

      // validate equipments
      let validEquipments = true;
      if (this.$refs.formEquipments && this.$refs.formEquipments[0]) {
        const valid = this.$refs.formEquipments[0].validate();
        if (valid) {
          this.formEquipments[this.isEditingEquipment] = valid;
        } else {
          validEquipments = false;
        }
      }

      // validate contacts
      let validContacts = true;
      if (this.$refs.formContacts && this.$refs.formContacts[0]) {
        const valid = this.$refs.formContacts[0].validate();
        if (valid) {
          this.formContacts[this.isEditingContact] = valid;
        } else {
          validContacts = false;
        }
      }
      if (validOrga && validMaintenances && validSubscriptions
        && validEquipments && validPropositions && validContacts) {
        createOrganisation(validOrga).then((res) => {
          const newId = res.data.organisation.id;
          // validate maintenances
          this.formMaintenances.forEach(async (el) => {
            const m = el;
            m.organisation = newId;
            if (m.product && m.product.id) {
              m.product = m.product.id;
            }
            m.contacts = m.contacts.map((contact) => contact.id);
            // await updateMaintenance(el.id, { organisation: newId });
            await createMaintenance(m);
          });
          // validate subscriptions
          this.formSubscriptions.forEach(async (el) => {
            const m = el;
            m.organisation = newId;
            if (m.name && m.name.id) {
              m.name = m.name.id;
            }
            if (m.duration && m.duration.id) {
              m.duration = m.duration.id;
            }
            await createSubscription(m);
          });
          // validate propositions
          this.formPropositions.forEach(async (el) => {
            const m = el;
            m.organisation = newId;
            if (m.percent && m.percent.id) {
              m.percent = m.percent.id;
            }
            await createProposition(m);
          });
          // validate equipments
          this.formEquipments.forEach(async (el) => {
            const m = el;
            m.organisation = newId;
            if (m.name && m.name.id) {
              m.name = m.name.id;
            }
            if (m.service_provider && m.service_provider.id) {
              m.service_provider = m.service_provider.id;
            }
            await createEquipment(m);
          });
          // validate contacts
          this.formContacts.forEach(async (el) => {
            const m = el;
            m.organisation = newId;
            await createContact(m);
          });
          this.$router.push({ name: 'dashboard_organisations' });
        });
      } else if (validContacts) {
        this.validationDialog = true;
      }
    },
    addNewMaintenance() {
      if (this.$refs.formMaintenances && this.$refs.formMaintenances[0]) {
        const valid = this.$refs.formMaintenances[0].validate();
        if (valid) {
          this.formMaintenances[this.isEditingMaintenance] = valid;
          this.formMaintenances.unshift({
            contacts: [],
          });
          this.isEditingMaintenance = 0;
          console.log('add');
          console.log(this.formMaintenances);
        } else {
          this.validationDialog = true;
        }
      } else {
        this.formMaintenances.unshift({
          contacts: [],
        });
        this.isEditingMaintenance = 0;
        console.log('add');
        console.log(this.formMaintenances);
      }
    },
    addSubscription() {
      if (this.$refs.formSubscriptions && this.$refs.formSubscriptions[0]) {
        const valid = this.$refs.formSubscriptions[0].validate();
        if (valid) {
          this.formSubscriptions[this.isEditingSubscription] = valid;
          this.formSubscriptions.unshift({});
          this.isEditingSubscription = 0;
        } else {
          this.validationDialog = true;
        }
      } else {
        this.formSubscriptions.unshift({});
        this.isEditingSubscription = 0;
      }
    },
    addProposition() {
      if (this.$refs.formPropositions && this.$refs.formPropositions[0]) {
        const valid = this.$refs.formPropositions[0].validate();
        if (valid) {
          this.formPropositions[this.isEditingProposition] = valid;
          this.formPropositions.unshift({});
          this.isEditingProposition = 0;
        } else {
          this.validationDialog = true;
        }
      } else {
        this.formPropositions.unshift({});
        this.isEditingProposition = 0;
      }
    },
    addEquipment() {
      if (this.$refs.formEquipments && this.$refs.formEquipments[0]) {
        const valid = this.$refs.formEquipments[0].validate();
        if (valid) {
          this.formEquipments[this.isEditingEquipment] = valid;
          this.formEquipments.unshift({});
          this.isEditingEquipment = 0;
        } else {
          this.validationDialog = true;
        }
      } else {
        this.formEquipments.unshift({});
        this.isEditingEquipment = 0;
      }
    },
    addContact() {
      if (this.$refs.formContacts && this.$refs.formContacts[0]) {
        const valid = this.$refs.formContacts[0].validate();
        if (valid) {
          this.formContacts[this.isEditingContact] = valid;
          this.formContacts.unshift({});
          this.isEditingContact = 0;
        }
      } else {
        this.formContacts.unshift({});
        this.isEditingContact = 0;
      }
    },
    stopEditingMaintenance() {
      if (this.$refs.formMaintenances) {
        const valid = this.$refs.formMaintenances[0].validate();
        if (valid) {
          this.formMaintenances[this.isEditingMaintenance] = valid;
          this.isEditingMaintenance = null;
        } else {
          this.validationDialog = true;
        }
      }
    },
    addSelectedContact() {
      if (this.$refs.formContacts && this.$refs.formContacts[0]) {
        const valid = this.$refs.formContacts[0].validate();
        if (valid) {
          this.formContacts[this.isEditingContact] = valid;
          if (!this.formContacts.find((el) => el.id === this.selectedContact)) {
            this.formContacts.unshift(this.contacts.find((el) => el.id === this.selectedContact));
            this.selectedContact = null;
          }
        }
      } else {
        console.log('else');
        if (!this.formContacts.find((el) => el.id === this.selectedContact)) {
          this.formContacts.unshift(this.contacts.find((el) => el.id === this.selectedContact));
          this.selectedContact = null;
        }
      }
    },
    stopEditingSubscription() {
      if (this.$refs.formSubscriptions && this.$refs.formSubscriptions[0]) {
        const valid = this.$refs.formSubscriptions[0].validate();
        if (valid) {
          this.formSubscriptions[this.isEditingSubscription] = valid;
          this.isEditingSubscription = null;
        } else {
          this.validationDialog = true;
        }
      }
    },
    stopEditingProposition() {
      if (this.$refs.formPropositions && this.$refs.formPropositions[0]) {
        const valid = this.$refs.formPropositions[0].validate();
        if (valid) {
          this.formPropositions[this.isEditingProposition] = valid;
          this.isEditingProposition = null;
        } else {
          this.validationDialog = true;
        }
      }
    },
    stopEditingEquipment() {
      if (this.$refs.formEquipments && this.$refs.formEquipments[0]) {
        const valid = this.$refs.formEquipments[0].validate();
        if (valid) {
          this.formEquipments[this.isEditingEquipment] = valid;
          this.isEditingEquipment = null;
        } else {
          this.validationDialog = true;
        }
      }
    },
    stopEditingContact() {
      if (this.$refs.formContacts && this.$refs.formContacts[0]) {
        const valid = this.$refs.formContacts[0].validate();
        if (valid) {
          this.formContacts[this.isEditingContact] = valid;
          this.isEditingContact = null;
        } else {
          this.validationDialog = true;
        }
      }
    },
    editMaintenance(id) {
      if (this.$refs.formMaintenances && this.$refs.formMaintenances[0]) {
        const valid = this.$refs.formMaintenances[0].validate();
        if (valid) {
          this.formMaintenances[this.isEditingMaintenance] = valid;
          this.isEditingMaintenance = id;
        } else {
          this.validationDialog = true;
        }
      } else {
        this.isEditingMaintenance = id;
      }
    },
    editSubscription(id) {
      if (this.$refs.formSubscriptions && this.$refs.formSubscriptions[0]) {
        const valid = this.$refs.formSubscriptions[0].validate();
        if (valid) {
          this.formSubscriptions[this.isEditingSubscription] = valid;
          this.isEditingSubscription = id;
        } else {
          this.validationDialog = true;
        }
      } else {
        this.isEditingSubscription = id;
      }
    },
    editProposition(id) {
      if (this.$refs.formPropositions && this.$refs.formPropositions[0]) {
        const valid = this.$refs.formPropositions[0].validate();
        if (valid) {
          this.formPropositions[this.isEditingProposition] = valid;
          this.isEditingProposition = id;
        } else {
          this.validationDialog = true;
        }
      } else {
        this.isEditingProposition = id;
      }
    },
    editEquipment(id) {
      if (this.$refs.formEquipments && this.$refs.formEquipments[0]) {
        const valid = this.$refs.formEquipments[0].validate();
        if (valid) {
          this.formEquipments[this.isEditingEquipment] = valid;
          this.isEditingEquipment = id;
        } else {
          this.validationDialog = true;
        }
      } else {
        this.isEditingEquipment = id;
      }
    },
    editContact(id) {
      if (this.$refs.formContacts && this.$refs.formContacts[0]) {
        const valid = this.$refs.formContacts[0].validate();
        if (valid) {
          this.formContacts[this.isEditingContact] = valid;
          this.isEditingContact = id;
        } else {
          this.validationDialog = true;
        }
      } else {
        this.isEditingContact = id;
      }
    },
    deleteMaintenance(id) {
      if (this.$refs.formMaintenances && this.$refs.formMaintenances[0]
        && this.isEditingMaintenance !== id) {
        const valid = this.$refs.formMaintenances[0].validate();
        if (valid) {
          this.formMaintenances[this.isEditingMaintenance] = valid;
          this.isEditingMaintenance = null;
          this.formMaintenances.splice(id, 1);
        } else {
          this.validationDialog = true;
        }
      } else {
        this.formMaintenances.splice(id, 1);
        this.isEditingMaintenance = null;
      }
    },
    deleteSubscription(id) {
      if (this.$refs.formSubscriptions && this.$refs.formSubscriptions[0]
        && this.isEditingSubscription !== id) {
        const valid = this.$refs.formSubscriptions[0].validate();
        if (valid) {
          this.formSubscriptions[this.isEditingSubscription] = valid;
          this.isEditingSubscription = null;
          this.formSubscriptions.splice(id, 1);
        } else {
          this.validationDialog = true;
        }
      } else {
        this.formSubscriptions.splice(id, 1);
        this.isEditingSubscription = null;
      }
    },
    deleteProposition(id) {
      if (this.$refs.formPropositions && this.$refs.formPropositions[0]) {
        if (this.isEditingProposition !== id) {
          const valid = this.$refs.formPropositions[0].validate();
          if (valid) {
            this.formPropositions[this.isEditingProposition] = valid;
            this.isEditingProposition = null;
            this.formPropositions.splice(id, 1);
          } else {
            this.validationDialog = true;
          }
        } else {
          this.formPropositions.splice(id, 1);
          this.isEditingProposition = null;
        }
      } else {
        this.formPropositions.splice(id, 1);
      }
    },
    deleteEquipment(id) {
      if (this.$refs.formEquipments && this.$refs.formEquipments[0]) {
        if (this.isEditingEquipment !== id) {
          const valid = this.$refs.formEquipments[0].validate();
          if (valid) {
            this.formEquipments[this.isEditingEquipment] = valid;
            this.isEditingEquipment = null;
            this.formEquipments.splice(id, 1);
          } else {
            this.validationDialog = true;
          }
        } else {
          this.formEquipments.splice(id, 1);
          this.isEditingEquipment = null;
        }
      } else {
        this.formEquipments.splice(id, 1);
      }
    },
    deleteContact(id) {
      if (this.$refs.formContacts && this.$refs.formContacts[0]
        && this.isEditingContact !== id) {
        const valid = this.$refs.formContacts[0].validate();
        if (valid) {
          this.formContacts[this.isEditingContact] = valid;
          this.isEditingContact = null;
          this.formContacts.splice(id, 1);
        } else {
          this.validationDialog = true;
        }
      } else {
        this.formContacts.splice(id, 1);
        this.isEditingContact = null;
      }
    },
    ...mapActions({
      register: 'auth/register',
    }),
  },
};
</script>

<style>
  .required .v-input::after {
    content: "*";
    color: #ff0000;
    margin-left: 1rem;
  }
</style>
