<template>
  <v-card>
    <v-app-bar dark color="#313131" flat>
      <v-card-title>
          <span class="headline">Édition de l'abonnement {{ form.name || '' }}</span>
      </v-card-title>
    </v-app-bar>

    <v-card-text>
      <v-container class="mt-4">
        <v-form class="ma-2"
                ref="form"
                v-model="valid"
                lazy-validation>
          <v-row>
            <v-col>
              <v-text-field outlined
                            v-model="form.name"
                            :rules="[v => !!v || 'Le nom est requis']"
                            label="Nom"
                            v-model.trim="$v.form.name.$model"
                            required></v-text-field>
            </v-col>
            <v-col class="required">
              <v-select v-model="form.organisation"
                        :items="organisations"
                        item-text="name"
                        item-value="id"
                        persistent-hint
                        outlined
                        label="Organisation"
                        :loading="loadingOrganisations"
                        disabled></v-select>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="4"
                   class="required">
              <v-text-field outlined
                            v-model.number="form.price"
                            :rules="priceRules"
                            label="Montant"
                            v-model.trim="$v.form.price.$model"
                            required></v-text-field>
            </v-col>
            <v-col>
              <v-menu v-model="startDateDialog"
                      :close-on-content-click="false"
                      :nudge-right="0"
                      transition="scale-transition"
                      offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="form.start_date"
                                outlined
                                label="Début"
                                readonly
                                v-bind="attrs"
                                v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="form.start_date"
                               @input="startDateDialog = false"></v-date-picker>
              </v-menu>
            </v-col>
            <v-col>
              <v-menu v-model="endDateDialog"
                      :close-on-content-click="false"
                      :nudge-right="0"
                      transition="scale-transition"
                      offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="form.deadline"
                                outlined
                                label="Fin"
                                readonly
                                v-bind="attrs"
                                v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="form.deadline"
                               @input="endDateDialog = false"></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card-text>

    <v-footer padless>
      <v-spacer />
      <v-card-actions>
        <v-btn
          color="primary"
          text
          exact
          :to="{ name: 'dashboard_subscriptions' }"
        >
          Annuler
        </v-btn>
        <v-btn
          color="primary"
          text
          exact
          @click="validate"
        >
          Enregistrer
        </v-btn>
      </v-card-actions>
    </v-footer>
  </v-card>
</template>

<script>
import { required, numeric } from 'vuelidate/lib/validators';
import { getOrganisations } from '../../services/organisation.service';
import { getSubscription, updateSubscription } from '../../services/subscription.service';

export default {
  name: 'edit-subscription',
  validations: {
    form: {
      price: {
        required,
        numeric,
      },
      name: {
        required,
      },
    },
  },

  data: () => ({
    priceRules: [
      (v) => !!v || 'Le montant est requis',
      (v) => Number.isInteger(Number(v)) || 'The value must be an integer number',
    ],
    form: {
      name: null,
      organisation: null,
      price: null,
      start_date: null,
      deadline: null,
    },
    valid: true,
    startDateDialog: false,
    endDateDialog: false,
    organisations: [],
    loadingOrganisations: false,
  }),
  methods: {
    async fetchSubscription(newRoute) {
      this.loadingOrganisations = true;
      getOrganisations().then((res) => {
        this.organisations = res.data;
      }).catch((err) => {
        console.log(err);
      }).finally(() => {
        this.loadingOrganisations = false;
      });

      getSubscription(decodeURIComponent(newRoute.params.id)).then((value) => {
        this.form = value.data;
        this.form.start_date = new Date(this.form.start_date)
          .toISOString().substr(0, 10);
        this.form.deadline = new Date(this.form.deadline).toISOString().substr(0, 10);
      })
        .catch((err) => console.log(err));
    },
    validate() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$refs.form.validate();
      } else {
        updateSubscription(this.form.id, this.form).then(() => {
          this.$router.push({ name: 'dashboard_subscriptions' });
        });
      }
    },
  },
  watch: {
    $route: {
      handler: 'fetchSubscription',
      immediate: true,
    },
  },
};
</script>

<style>
  .required .v-input::after {
    content: "*";
    color: #ff0000;
    margin-left: 1rem;
  }
</style>
