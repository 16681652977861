<template>
  <v-container>
  <v-data-table :headers="headers"
      :items="catalogs"
      :search="search"
      calculate-widths
      :loading="loading">
    <div class="text-center pt-2">
      <v-pagination
        v-model="page"
        :length="pageCount"
      ></v-pagination>
    </div>
    <template #top>
      <v-container>
        <v-row align="center">
          <v-btn rounded
                 disabled
                 color="primary"
                 dark
                 class="mb-2 btn-disabled"
                 :to="{ name: 'dashboard_new_catalog' }">
            Ajouter un catalogue
            <v-icon right>
              mdi-plus
            </v-icon>
          </v-btn>
          <v-dialog v-model="dialogNew"
                    persistent
                    scrollable
                    :fullscreen="$vuetify.breakpoint.mdAndDown"
                    max-width="1200px">
            <router-view />
          </v-dialog>
          <v-dialog v-model="dialogEdit"
                    persistent
                    scrollable
                    :fullscreen="$vuetify.breakpoint.mdAndDown"
                    max-width="1200px">
            <router-view />
          </v-dialog>

          <v-spacer />

          <v-text-field v-model="search"
                        label="Rechercher"
                        append-icon="mdi-magnify"
                        single-line
                        hide-details />
        </v-row>
      </v-container>
    </template>

    <template #item.admin="{ item }">
      <span :class="{
        'font-weight-medium': item.admin,
        'font-weight-regular': !item.admin,
      }">
        {{ item.admin ? 'Administrateur' : 'Client' }}
      </span>
    </template>

    <template #item.customer="{ item }">
      <router-link v-if="item.Customer"
                   :to="getCustomerTo(item.Customer)">
        Lien
      </router-link>
      <span v-else>Non</span>
    </template>

    <template #item.actions="{ item }">
      <v-btn icon
             class="mr-2"
             :to="{ name: 'dashboard_view_catalog',
        params: { id: encodeURIComponent(item.id) } }">
        <v-icon small>mdi-eye</v-icon>
      </v-btn>
      <v-btn icon
             class="mr-2"
             :to="{ name: 'dashboard_edit_catalog',
        params: { id: encodeURIComponent(item.id) } }">
        <v-icon small>mdi-pencil</v-icon>
      </v-btn>

      <v-btn icon disabled @click="deleteCatalog(item.id)">
        <v-icon small>mdi-delete</v-icon>
      </v-btn>
    </template>

    <template #no-results>
      Aucun résultat trouvé :/
    </template>

    <template #no-data>
      Aucun catalogue n'a pu être récupéré :/
    </template>
  </v-data-table>
  </v-container>
</template>
<script>
import { getCatalogs, deleteCatalog } from '@/services/catalog.service';

export default {
  name: 'Catalogs',

  created() {
    this.fetchCatalogs();
  },

  data: () => ({
    headers: [
      {
        text: 'Nom',
        align: 'start',
        value: 'name',
      },
      {
        text: 'Actions',
        sortable: false,
        value: 'actions',
      },
    ],
    loading: false,
    search: null,
    catalogs: [],
    dialogNew: false,
    dialogEdit: false,
    page: 0,
    pageCount: 0,
    itemsPerPage: 10,
  }),
  methods: {
    fetchCatalogs() {
      this.loading = true;
      getCatalogs(this.itemsPerPage, this.page)
        .then((value) => {
          this.catalogs = value.data;
        })
        .catch(console.error)
        .finally(() => {
          this.loading = false;
        });
    },
    getCustomerTo(id) {
      return {
        name: 'dashboard_edit_client',
        params: {
          clientId: id,
        },
      };
    },
    deleteCatalog(email) {
      deleteCatalog(email)
        .then(() => {
          console.log('success!');
          this.fetchCatalogs();
        })
        .catch(console.error);
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(newRoute) {
        this.dialogNew = newRoute.meta?.showDialogNew;
        this.dialogEdit = newRoute.meta?.showDialogEdit;
        this.fetchCatalogs();
      },
    },
  },
};
</script>
<style scoped>
  .theme--dark.v-btn.v-btn--disabled.v-btn--has-bg.btn-disabled {
    background-color: rgba(0,0,0,.4) !important;
  }
</style>
