<template>
  <v-card>
    <v-app-bar dark color="#313131" flat>
      <v-card-title>
          <span class="headline">Édition de l'organisation {{ form.name || '' }}</span>
      </v-card-title>
    </v-app-bar>

    <v-card-text>
      <v-container class="mt-4">
        <v-form class="ma-2"
                ref="form"
                v-model="valid"
                lazy-validation>
          <v-row>
            <v-col>
              <v-text-field outlined
                            v-model.number="form.price"
                            :rules="priceRules"
                            label="Montant"
                            v-model.trim="$v.form.price.$model"
                            required></v-text-field>
            </v-col>
            <v-col class="required">
              <v-select v-model="form.organisation"
                        :items="organisations"
                        item-text="name"
                        item-value="id"
                        persistent-hint
                        outlined
                        label="Organisation"
                        :loading="loadingOrganisations"
                        disabled></v-select>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="4"
                   class="required">
              <v-text-field v-model="form.url"
                            v-model.trim="$v.form.url.$model"
                            outlined
                            :rules="[v => !!v || 'L\'url est requise']"
                            label="Url"
                            required>
              </v-text-field>
            </v-col>
            <v-col>
              <v-menu v-model="subscriptionDateDialog"
                      :close-on-content-click="false"
                      :nudge-right="0"
                      transition="scale-transition"
                      offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="form.subscription_date"
                                outlined
                                label="Date de souscription"
                                readonly
                                v-bind="attrs"
                                v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="form.subscription_date"
                               @input="subscriptionDateDialog = false"></v-date-picker>
              </v-menu>
            </v-col>
            <v-col>
              <v-menu v-model="deadlineDateDialog"
                      :close-on-content-click="false"
                      :nudge-right="0"
                      transition="scale-transition"
                      offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="form.deadline"
                                outlined
                                label="Echéance"
                                readonly
                                v-bind="attrs"
                                v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="form.deadline"
                               @input="deadlineDateDialog = false"></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-spacer />
          <h2 class="mb-2">Contacts</h2>
          <v-btn @click="addContact" class="mb-2">Ajouter un contact</v-btn>
          <v-spacer />
          <v-container>
            <v-row v-for="(contact, index) in form.contacts" :key="index">
              <v-col cols="4">
                <v-select v-model="form.contacts[index]"
                          persistent-hint
                          outlined
                          :items="contacts"
                          item-text="firstname"
                          item-value="id"
                          :rules="[v => !!v || 'Ce champ est requis']"
                          label="Contact"
                          :loading="loadingContacts"
                          required></v-select>
              </v-col>
              <v-col>
                <span v-if="contact.lastname">
                  {{ contact.lastname }}
                </span>
              </v-col>
              <v-col>
                <v-btn icon @click="deleteContact(index)"><v-icon>mdi-delete</v-icon></v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-container>
    </v-card-text>

    <v-footer padless>
      <v-spacer />
      <v-card-actions>
        <v-btn
          color="primary"
          text
          exact
          :to="{ name: 'dashboard_maintenances' }"
        >
          Annuler
        </v-btn>
        <v-btn
          color="primary"
          text
          exact
          @click="validate"
        >
          Enregistrer
        </v-btn>
      </v-card-actions>
    </v-footer>
  </v-card>
</template>

<script>
import { required, numeric } from 'vuelidate/lib/validators';
import { getOrganisations } from '../../services/organisation.service';
import { getContacts } from '../../services/contact.service';
import { getMaintenance, updateMaintenance } from '../../services/maintenance.service';

export default {
  name: 'edit-maintenance',
  validations: {
    form: {
      price: {
        required,
        numeric,
      },
      url: {
        required,
      },
    },
  },

  data: () => ({
    priceRules: [
      (v) => !!v || 'Le montant est requis',
      (v) => Number.isInteger(Number(v)) || 'The value must be an integer number',
    ],
    form: {
      organisation: null,
      price: null,
      url: null,
      contacts: [],
      subscription_date: new Date().toISOString().substr(0, 10),
      deadline: new Date().toISOString().substr(0, 10),
    },
    valid: true,
    subscriptionDateDialog: false,
    deadlineDateDialog: false,
    loadingCategories: false,
    organisations: [],
    loadingOrganisations: false,
    contacts: [],
    loadingContacts: false,
  }),
  methods: {
    async fetchMaintenance(newRoute) {
      this.loadingContacts = true;
      getContacts().then((res) => {
        this.contacts = res.data;
      }).catch((err) => {
        console.log(err);
      }).finally(() => {
        this.loadingContacts = false;
      });

      this.loadingOrganisations = true;
      getOrganisations().then((res) => {
        this.organisations = res.data;
      }).catch((err) => {
        console.log(err);
      }).finally(() => {
        this.loadingOrganisations = false;
      });

      getMaintenance(decodeURIComponent(newRoute.params.id)).then((value) => {
        this.form = value.data;
        this.form.subscription_date = new Date(this.form.subscription_date)
          .toISOString().substr(0, 10);
        this.form.deadline = new Date(this.form.deadline).toISOString().substr(0, 10);
      })
        .catch((err) => console.log(err));
    },
    addContact() {
      this.form.contacts.push('');
    },
    deleteContact(id) {
      this.form.contacts.splice(id, 1);
    },
    validate() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$refs.form.validate();
      } else {
        this.form.contacts = this.form.contacts.map((el) => (el.id ? el.id : el));
        updateMaintenance(this.form.id, this.form).then(() => {
          this.$router.push({ name: 'dashboard_maintenances' });
        });
      }
    },
  },
  watch: {
    $route: {
      handler: 'fetchMaintenance',
      immediate: true,
    },
  },
};
</script>

<style>
  .required .v-input::after {
    content: "*";
    color: #ff0000;
    margin-left: 1rem;
  }
</style>
