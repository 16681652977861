<template>
  <v-card>
    <v-app-bar dark color="#313131" flat>
      <v-card-title>
          <span class="headline">Visualisation d'un catalogue</span>
      </v-card-title>
    </v-app-bar>

    <v-card-text>
      <v-container class="mt-4">
        <v-row align="center">
          <v-col cols="4"
              class="required">
            <p><span style="font-weight: bold;">Nom :</span> </p>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>

    <v-footer padless>
      <v-spacer />
      <v-card-actions>
        <v-btn
          color="primary"
          text
          exact
          :to="{ name: 'dashboard_organisations' }"
        >
          Fermer
        </v-btn>
      </v-card-actions>
    </v-footer>
  </v-card>
</template>

<script>
import { getOrganisations, deleteOrganisation } from '@/services/organisation.service';

export default {
  name: 'Clients',

  created() {
    this.fetchOrganisations();
  },

  data: () => ({
    loading: false,
    search: null,
    organisations: [],
    dialogNew: false,
    dialogEdit: false,
  }),
  methods: {
    fetchOrganisations() {
      this.loading = true;
      getOrganisations()
        .then((value) => {
          this.organisations = value.data;
        })
        .catch(console.error)
        .finally(() => {
          this.loading = false;
        });
    },
    getCustomerTo(id) {
      return {
        name: 'dashboard_edit_client',
        params: {
          clientId: id,
        },
      };
    },
    deleteOrganisation(id) {
      deleteOrganisation(id)
        .then(() => {
          console.log('success!');
          this.fetchOrganisations();
        })
        .catch(console.error);
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(newRoute) {
        this.dialogNew = newRoute.meta?.showDialogNew;
        this.dialogEdit = newRoute.meta?.showDialogEdit;
        this.fetchOrganisations();
      },
    },
  },
};
</script>

<style>
  .required .v-input::after {
    content: "*";
    color: #ff0000;
    margin-left: 1rem;
  }
</style>
