<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :return-value.sync="newDate"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template #activator="{ on, attrs }">
      <v-text-field
        v-model="newDate"
        :append-icon="icon"
        readonly
        v-bind="attrs"
        v-on="on"
      />
    </template>
    <v-date-picker
      v-model="newDate"
      no-title
      scrollable
    >
      <v-spacer />
      <v-btn text color="primary" @click="menu = false">Annuler</v-btn>
      <v-btn text color="primary" @click="$refs.menu.save(newDate)">OK</v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
export default {
  name: 'DatePicker',
  data: () => ({
    menu: false,
  }),
  computed: {
    newDate: {
      get() {
        return new Date(this.date).toISOString().substring(0, 10);
      },
      set(v) {
        // console.log(v);
        const val = new Date(v).toISOString().substring(0, 10);
        this.$emit('update:date', val);
        return val;
      },
    },
  },
  props: {
    date: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: 'mdi-calendar',
    },
  },
};
</script>

<style scoped>

</style>
