<template>
  <v-container>
    <v-data-table
      :headers="headers"
      :items="organisations"
      :items-per-page="10"
      :search="search"
      calculate-widths
      :loading="loading"
    >
      <template #top>
        <v-container>
          <v-row align="center">
            <v-btn
              rounded
              color="primary"
              dark
              class="mb-2"
              :to="{ name: 'dashboard_new_organisation' }"
            >
              Ajouter une organisation
              <v-icon right>
                mdi-plus
              </v-icon>
            </v-btn>
            <v-dialog
              v-model="dialogNew"
              persistent
              scrollable
              :fullscreen="$vuetify.breakpoint.mdAndDown"
              max-width="1200px"
            >
              <router-view />
            </v-dialog>
            <v-dialog
              v-model="dialogEdit"
              persistent
              scrollable
              :fullscreen="$vuetify.breakpoint.mdAndDown"
              max-width="1200px"
            >
              <router-view />
            </v-dialog>

            <v-spacer />

            <v-text-field
              v-model="search"
              label="Rechercher"
              append-icon="mdi-magnify"
              single-line
              hide-details
            />
          </v-row>
        </v-container>
      </template>

      <template #item.name="{ item }">
        <v-btn text
               :to="{ name: 'dashboard_edit_organisation',
               params: { id: encodeURIComponent(item.id) }}">
          {{ item.name }}
        </v-btn>
      </template>

      <template #no-results>
        Aucun résultat trouvé :/
      </template>

      <template #no-data>
        Aucune organisation n'a pu être récupérée :/
      </template>

      <template #item.actions="{ item }">
        <v-btn icon @click="deleteOrganisation(item.id)">
          <v-icon small>mdi-delete</v-icon>
        </v-btn>
      </template>

      <template #no-results>
        Aucun résultat trouvé :/
      </template>

      <template #no-data>
        Aucune organisation n'a pu être récupérée :/
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { getOrganisations, deleteOrganisation } from '@/services/organisation.service';

export default {
  name: 'Organisations',

  created() {
    this.fetchOrganisations();
  },

  data: () => ({
    headers: [
      {
        text: 'Nom',
        align: 'start',
        value: 'name',
      },
      {
        text: 'Email',
        value: 'email',
      },
      {
        text: 'Téléphone',
        value: 'phone',
      },
      {
        text: 'Siret',
        value: 'siret',
      },
      {
        text: 'Actions',
        sortable: false,
        value: 'actions',
      },
    ],
    loading: false,
    search: null,
    organisations: [],
    dialogNew: false,
    dialogEdit: false,
  }),
  methods: {
    fetchOrganisations() {
      this.loading = true;
      getOrganisations()
        .then((value) => {
          this.organisations = value.data;
        })
        .catch(console.error)
        .finally(() => {
          this.loading = false;
        });
    },
    getOrganisationTo(id) {
      return {
        name: 'dashboard_edit_client',
        params: {
          clientId: id,
        },
      };
    },
    deleteOrganisation(id) {
      deleteOrganisation(id)
        .then(() => {
          console.log('success!');
          this.fetchOrganisations();
        })
        .catch(console.error);
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(newRoute) {
        this.dialogNew = newRoute.meta?.showDialogNew;
        this.dialogEdit = newRoute.meta?.showDialogEdit;
        this.fetchOrganisations();
      },
    },
  },
};
</script>

<style scoped>

</style>
