<template>
  <v-card>
    <v-app-bar dark color="#313131" flat>
      <v-card-title>
          <span class="headline">Visualisation d'une proposition</span>
      </v-card-title>
    </v-app-bar>

    <v-card-text>
      <v-container class="mt-4">
        <v-row align="center">
          <v-col cols="4"
                 class="required">
            <p><span style="font-weight: bold;">Type :</span>{{proposition.type}}</p>
          </v-col>
          <v-col cols="4"
                 class="required">
            <p><span style="font-weight: bold;">Description :</span>{{proposition.description}}</p>
          </v-col>
          <v-col cols="4"
                 class="required">
            <p>
              <span style="font-weight: bold;">Date :</span>
              {{proposition.date}}
            </p>
          </v-col>
          <v-col cols="4"
                 class="required">
            <p>
              <span style="font-weight: bold;">CDE :</span>
              {{proposition.cde}}
            </p>
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col cols="4"
                 class="required">
            <p><span style="font-weight: bold;">Montant :</span>{{proposition.price}}</p>
          </v-col>
          <v-col cols="4"
                 class="required">
            <p><span style="font-weight: bold;">Pourcentage :</span>{{proposition.percent}}</p>
          </v-col>
          <v-col cols="4">
            <p>
              <span style="font-weight: bold;" v-if="proposition.organisation">Organisation :</span>
              {{ proposition.organisation ? proposition.organisation.name : '' }}
            </p>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>

    <v-footer padless>
      <v-spacer />
      <v-card-actions>
        <v-btn
          color="primary"
          text
          exact
          :to="{ name: 'dashboard_propositions' }"
        >
          Fermer
        </v-btn>
      </v-card-actions>
    </v-footer>
  </v-card>
</template>

<script>
import { getProposition, deleteProposition } from '@/services/proposition.service';

export default {
  name: 'view-proposition',
  created() {
    // this.fetchOrganisation();
  },

  data: () => ({
    loading: false,
    proposition: {
    },
    dialogNew: false,
    dialogEdit: false,
  }),
  methods: {
    fetchProposition(newRoute) {
      this.loading = true;
      getProposition(decodeURIComponent(newRoute.params.id))
        .then((value) => {
          console.log(value);
          this.proposition = value.data;
        })
        .catch(console.error)
        .finally(() => {
          this.loading = false;
        });
    },
    getPropositionTo(id) {
      return {
        name: 'dashboard_edit_proposition',
        params: {
          id,
        },
      };
    },
    deleteProposition(id) {
      deleteProposition(id)
        .then(() => {
          console.log('success!');
        })
        .catch(console.error);
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(newRoute) {
        this.dialogNew = newRoute.meta?.showDialogNew;
        this.dialogEdit = newRoute.meta?.showDialogEdit;
        this.fetchProposition(newRoute);
      },
    },
  },
};
</script>

<style>
  .required .v-input::after {
    content: "*";
    color: #ff0000;
    margin-left: 1rem;
  }
</style>
