var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.catalogs,"search":_vm.search,"calculate-widths":"","loading":_vm.loading},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-container',[_c('v-row',{attrs:{"align":"center"}},[_c('v-btn',{staticClass:"mb-2 btn-disabled",attrs:{"rounded":"","disabled":"","color":"primary","dark":"","to":{ name: 'dashboard_new_catalog' }}},[_vm._v(" Ajouter un catalogue "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-plus ")])],1),_c('v-dialog',{attrs:{"persistent":"","scrollable":"","fullscreen":_vm.$vuetify.breakpoint.mdAndDown,"max-width":"1200px"},model:{value:(_vm.dialogNew),callback:function ($$v) {_vm.dialogNew=$$v},expression:"dialogNew"}},[_c('router-view')],1),_c('v-dialog',{attrs:{"persistent":"","scrollable":"","fullscreen":_vm.$vuetify.breakpoint.mdAndDown,"max-width":"1200px"},model:{value:(_vm.dialogEdit),callback:function ($$v) {_vm.dialogEdit=$$v},expression:"dialogEdit"}},[_c('router-view')],1),_c('v-spacer'),_c('v-text-field',{attrs:{"label":"Rechercher","append-icon":"mdi-magnify","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)]},proxy:true},{key:"item.admin",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{
      'font-weight-medium': item.admin,
      'font-weight-regular': !item.admin,
    }},[_vm._v(" "+_vm._s(item.admin ? 'Administrateur' : 'Client')+" ")])]}},{key:"item.customer",fn:function(ref){
    var item = ref.item;
return [(item.Customer)?_c('router-link',{attrs:{"to":_vm.getCustomerTo(item.Customer)}},[_vm._v(" Lien ")]):_c('span',[_vm._v("Non")])]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"icon":"","to":{ name: 'dashboard_view_catalog',
      params: { id: encodeURIComponent(item.id) } }}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-eye")])],1),_c('v-btn',{staticClass:"mr-2",attrs:{"icon":"","to":{ name: 'dashboard_edit_catalog',
      params: { id: encodeURIComponent(item.id) } }}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1),_c('v-btn',{attrs:{"icon":"","disabled":""},on:{"click":function($event){return _vm.deleteCatalog(item.id)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-delete")])],1)]}},{key:"no-results",fn:function(){return [_vm._v(" Aucun résultat trouvé :/ ")]},proxy:true},{key:"no-data",fn:function(){return [_vm._v(" Aucun catalogue n'a pu être récupéré :/ ")]},proxy:true}])},[_c('div',{staticClass:"text-center pt-2"},[_c('v-pagination',{attrs:{"length":_vm.pageCount},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }