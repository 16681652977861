<template>
  <v-container>
    <v-data-table
      :headers="headers"
      :items="contacts"
      :search="search"
      :items-per-page="10"
      calculate-widths
      :loading="loading"
    >
      <template #top>
        <v-container>
          <v-row align="center">
            <v-btn
              rounded
              color="primary"
              dark
              class="mb-2"
              :to="{ name: 'dashboard_new_contact' }"
            >
              Ajouter un contact
              <v-icon right>
                mdi-plus
              </v-icon>
            </v-btn>
            <v-dialog
              v-model="dialogNew"
              persistent
              scrollable
              :fullscreen="$vuetify.breakpoint.mdAndDown"
              max-width="1200px"
            >
              <router-view />
            </v-dialog>
            <v-dialog
              v-model="dialogEdit"
              persistent
              scrollable
              :fullscreen="$vuetify.breakpoint.mdAndDown"
              max-width="1200px"
            >
              <router-view />
            </v-dialog>

            <v-spacer />

            <v-text-field
              v-model="search"
              label="Rechercher"
              append-icon="mdi-magnify"
              single-line
              hide-details
            />
          </v-row>
        </v-container>
      </template>

      <template #item.admin="{ item }">
        <span :class="{
          'font-weight-medium': item.admin,
          'font-weight-regular': !item.admin,
        }">
          {{ item.admin ? 'Administrateur' : 'Client' }}
        </span>
      </template>

      <template #item.customer="{ item }">
        <router-link
          v-if="item.Customer"
          :to="getCustomerTo(item.Customer)"
        >
          Lien
        </router-link>
        <span v-else>Non</span>
      </template>

      <template #item.actions="{ item }">
        <v-btn
          icon
          class="mr-2"
          :to="{ name: 'dashboard_view_contact', params:
               { id: encodeURIComponent(item.id) } }"
        >
          <v-icon small>mdi-eye</v-icon>
        </v-btn>
        <v-btn
          icon
          class="mr-2"
          :to="{ name: 'dashboard_edit_contact', params:
          { id: encodeURIComponent(item.id) } }"
        >
          <v-icon small>mdi-pencil</v-icon>
        </v-btn>

        <v-btn icon @click="deleteContact(item.id)">
          <v-icon small>mdi-delete</v-icon>
        </v-btn>
      </template>

      <template #no-results>
        Aucun résultat trouvé :/
      </template>

      <template #no-data>
        Aucun contact n'a pu être récupéré :/
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { getContacts, deleteContact } from '@/services/contact.service';

export default {
  name: 'Contacts',

  created() {
    this.fetchContacts();
  },

  data: () => ({
    headers: [
      {
        text: 'Nom',
        align: 'start',
        value: 'lastname',
      },
      {
        text: 'Prénom',
        value: 'firstname',
      },
      {
        text: 'Email',
        value: 'email',
      },
      {
        text: 'Fixe',
        value: 'phone',
      },
      {
        text: 'Mobile',
        value: 'phone_mobile',
      },
      {
        text: 'Actions',
        sortable: false,
        value: 'actions',
      },
    ],
    loading: false,
    search: null,
    contacts: [],
    dialogNew: false,
    dialogEdit: false,
  }),
  methods: {
    fetchContacts() {
      this.loading = true;
      getContacts()
        .then((value) => {
          this.contacts = value.data;
        })
        .catch(console.error)
        .finally(() => {
          this.loading = false;
        });
    },
    getCustomerTo(id) {
      return {
        name: 'dashboard_edit_client',
        params: {
          clientId: id,
        },
      };
    },
    deleteContact(id) {
      deleteContact(id)
        .then(() => {
          console.log('success!');
          this.fetchContacts();
        })
        .catch(console.error);
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(newRoute) {
        this.dialogNew = newRoute.meta?.showDialogNew;
        this.dialogEdit = newRoute.meta?.showDialogEdit;
        this.fetchContacts();
      },
    },
  },
};
</script>

<style scoped>

</style>
