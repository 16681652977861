import axios from 'axios';
import authHeader from './auth-header';

export function getEquipments() {
  return axios.get(`${process.env.VUE_APP_API_URL}equipments`, { headers: authHeader() });
}

export function getEquipment(id) {
  return axios.get(`${process.env.VUE_APP_API_URL}equipments/${encodeURIComponent(id)}`, { headers: authHeader() });
}

export function createEquipment(data) {
  return axios.post(`${process.env.VUE_APP_API_URL}equipments/`, data, { headers: authHeader() });
}

export function updateEquipment(id, data) {
  return axios.patch(`${process.env.VUE_APP_API_URL}equipments/${encodeURIComponent(id)}`, data, { headers: authHeader() });
}

export function deleteEquipment(id) {
  return axios.delete(`${process.env.VUE_APP_API_URL}equipments/${id}`, { headers: authHeader() });
}

export function getEquipmentByName(name) {
  return axios.get(`${process.env.VUE_APP_API_URL}equipments/name/${name}`, { headers: authHeader() });
}

export function getEquipmentsByOrganisation(id) {
  return axios.get(`${process.env.VUE_APP_API_URL}equipments/organisation/${encodeURIComponent(id)}`, { headers: authHeader() });
}
