<template>
  <v-card>
    <v-app-bar dark color="#313131" flat>
      <v-card-title>
          <span class="headline">Nouveau client</span>
      </v-card-title>
    </v-app-bar>

    <v-card-text>
      <v-container class="mt-4">
        <v-row class="mb-5 text-h6">
          Informations de base
        </v-row>
        <v-row align="center" class="py-4">
          Nom du client :
          <v-responsive max-width="250" class="mx-6 required">
            <v-text-field
              :rules="rules_name"
              append-icon="mdi-pencil"
              v-model="newClient.name" />
          </v-responsive>
          <v-row class="required">
            <v-file-input
              :rules="rules_logo"
              accept="image/png, image/jpeg, image/bmp"
              prepend-icon="mdi-camera"
              label="Logo du client"
              v-model="newClient.logo"
            />
          </v-row>
        </v-row>
        <v-alert
          type="error"
          v-if="errors.name && errors.name.length > 0">
        {{ errors.name }}
      </v-alert>
      <v-alert type="error" v-if="errors.logo && errors.logo.length > 0">
        {{ errors.logo }}
      </v-alert>
      </v-container>
      <v-divider />
      <base-client-form
        v-model="newClient"
        v-bind:errors="errors"
        ref="form"
        @change="onFilesChange"
      />
    </v-card-text>

    <v-footer padless>
      <v-spacer />
      <v-card-actions>
        <v-btn
          color="primary"
          text
          exact
          :to="{ name: 'dashboard_home' }"
        >
          Annuler
        </v-btn>
        <v-btn
          color="primary"
          text
          exact
          @click="newItem"
        >
          Enregistrer
        </v-btn>
      </v-card-actions>
    </v-footer>
  </v-card>
</template>

<script>
import BaseClientForm from '@/components/BaseClientForm.vue';
import { postCustomer } from '@/services/admin.service';
// import router from '../../../../back/routes/customer';

export default {
  name: 'new-client',
  components: { BaseClientForm },
  data: () => ({
    newClient: {
      name: '',
      logo: null,
      mail: '',
      maintenance: {
        until: new Date(),
      },
      hosting: {
        host: '',
        from: new Date(),
        until: new Date(),
      },
      domains: [
        {
          name: '',
          from: new Date(),
          until: new Date(),
        },
      ],
      documents: [],
    },
    documentFiles: [],
    loading: false,
    rules_logo: [
      (value) => !value || value.size < 2000000 || 'Avatar size must be less than 2 MB!',
    ],
    rules_name: [(value) => !!value || value.length <= 0 || 'Ce champs est requis !'],
    errors: {},
  }),
  methods: {
    generateFormData() {
      const res = new FormData();
      res.append('name', this.newClient.name);
      res.append('logo', this.newClient.logo);
      res.append('hosting.host', this.newClient.hosting.host);
      res.append('hosting.from', this.newClient.hosting.from);
      res.append('hosting.until', this.newClient.hosting.until);
      res.append('maintenance.until', this.newClient.maintenance.until);
      // Should it be sent separately?
      // res.append('email', this.newClient.mail);
      this.newClient.domains.forEach((domain, i) => {
        res.append(`domains.${i}.name`, domain.name);
        res.append(`domains.${i}.from`, domain.from);
        res.append(`domains.${i}.until`, domain.until);
      });
      this.newClient.documents.forEach((document, i) => {
        res.append(`documents.${i}.name`, document.file.name);
        res.append(`documents.${i}.category`, document.category);
      });
      this.documentFiles.forEach((documentFile) => {
        res.append('documents', documentFile);
      });
      return res;
    },
    validateForm() {
      let valid = true;

      // required name
      if (!this.newClient.name || this.newClient.name.length <= 0) {
        valid = false;
        this.$set(this.errors, 'name', 'Le champs Nom du client est requis.');
      } else {
        this.$set(this.errors, 'name', '');
      }

      // required logo
      if (!this.newClient.logo || this.newClient.logo.length <= 0) {
        valid = false;
        this.$set(this.errors, 'logo', 'Le champs Logo du client est requis.');
      } else {
        this.$set(this.errors, 'logo', '');
      }

      // required host
      if (!this.newClient.hosting.host || this.newClient.hosting.host.length <= 0) {
        valid = false;
        this.$set(this.errors, 'host', 'Ce champs est requis.');
      } else {
        this.$set(this.errors, 'host', '');
      }

      // required domain
      this.newClient.domains.forEach((domain) => {
        if (!domain.name || domain.name.length <= 0) {
          valid = false;
          this.$set(this.errors, 'domain', 'Ce champs est requis.');
        } else {
          this.$set(this.errors, 'domain', '');
        }
      });
      console.log(this.errors);
      return valid;
    },
    newItem() {
      this.loading = true;
      if (!this.validateForm()) {
        console.log('not valid');
        this.loading = false;
        return;
      }
      if (!this.newClient.logo) {
        this.newClient.logo = `https://picsum.photos/seed/${this.newClient.name}/500/300`;
      }

      const finalData = this.generateFormData();

      postCustomer(finalData)
        .then(() => {
          //
        })
        .catch(console.error)
        .finally(() => {
          this.loading = false;
          this.$router.push({ name: 'dashboard_home' });
        });
    },
    onFilesChange() {
      this.documentFiles = Array.from(this.$refs.form.files.values());
      console.log(this.documentFiles);
    },
  },
};
</script>

<style>
  .required .v-input::after{
      content:"*";
      color: #ff0000;
      margin-left: 1rem;
  }
</style>
