<template>
  <v-card>
    <v-app-bar dark color="#313131" flat>
      <v-card-title>
          <span class="headline">Visualisation d'un équipement</span>
      </v-card-title>
    </v-app-bar>

    <v-card-text>
      <v-container class="mt-4">
        <v-row align="center">
          <v-col cols="4"
              class="required">
            <p><span style="font-weight: bold;">Nom :</span> {{equipment.name}}</p>
          </v-col>
          <v-col cols="4"
              class="required">
            <p>
              <span style="font-weight: bold;">Prestataire : </span>
            {{equipment.service_provider}}
            </p>
          </v-col>
          <v-col cols="4"
              class="required">
            <p><span style="font-weight: bold;">URL : </span>
            {{equipment.url}}
            </p>
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col cols="4"
              class="required">
            <p><span style="font-weight: bold;">Montant : </span>{{equipment.price}}</p>
          </v-col>
          <v-col cols="4"
              class="required">
            <p>
              <span style="font-weight: bold;">Date de souscription : </span>
            {{equipment.start_date}}
            </p>
          </v-col>
          <v-col cols="4"
              class="required">
            <p><span style="font-weight: bold;">Echéance : </span>
            {{equipment.deadline}}
            </p>
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col cols="4">
            <p><span style="font-weight: bold;">Organisation : </span>
            {{ equipment.organisation.name}}
            </p>
          </v-col>
        </v-row>
        <v-row v-for="(contact, index) in equipment.contacts" :key="index">
          <v-col>{{ contact.firstname }} {{ contact.lastname}}</v-col>
          <v-col>{{ contact.email }}</v-col>
          <v-col>{{ contact.phone }}</v-col>
          <v-col>{{ contact.mobile_phone }}</v-col>
        </v-row>
      </v-container>
    </v-card-text>

    <v-footer padless>
      <v-spacer />
      <v-card-actions>
        <v-btn
          color="primary"
          text
          exact
          :to="{ name: 'dashboard_equipments' }"
        >
          Fermer
        </v-btn>
      </v-card-actions>
    </v-footer>
  </v-card>
</template>

<script>
import { getEquipment, deleteEquipment } from '@/services/equipment.service';

export default {
  name: 'view-equipment',
  created() {
    // this.fetchOrganisation();
  },

  data: () => ({
    loading: false,
    equipment: {
      organisation: {},
      contacts: [],
    },
    dialogNew: false,
    dialogEdit: false,
  }),
  methods: {
    fetchEquipment(newRoute) {
      this.loading = true;
      getEquipment(decodeURIComponent(newRoute.params.id))
        .then((value) => {
          console.log(value);
          this.equipment = value.data;
        })
        .catch(console.error)
        .finally(() => {
          this.loading = false;
        });
    },
    getEquipmentTo(id) {
      return {
        name: 'dashboard_edit_equipment',
        params: {
          id,
        },
      };
    },
    deleteEquipment(id) {
      deleteEquipment(id)
        .then(() => {
          console.log('success!');
        })
        .catch(console.error);
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(newRoute) {
        this.dialogNew = newRoute.meta?.showDialogNew;
        this.dialogEdit = newRoute.meta?.showDialogEdit;
        this.fetchEquipment(newRoute);
      },
    },
  },
};
</script>

<style>
  .required .v-input::after {
    content: "*";
    color: #ff0000;
    margin-left: 1rem;
  }
</style>
