<template>
  <v-card>
    <v-app-bar dark color="#313131" flat>
      <v-card-title>
        <span class="headline">Édition du catalogue {{ form.catalog.name || '' }}</span>
      </v-card-title>
    </v-app-bar>

    <v-card-text>
      <v-container class="mt-4">
        <v-form class="ma-2"
                ref="form"
                v-model="valid"
                lazy-validation>
          <h2 class="mb-2">Items</h2>
          <v-row>
            <v-text-field v-model="newItem"
                          outlined
                          :rules="[v => !!v || 'Un nom est requis']"
                          label="Nom"
                          required>
            </v-text-field>
            <v-btn @click="addItem" class="mb-2">Ajouter l'item</v-btn>
          </v-row>
          <v-container>
            <v-row v-for="(item, index) in form.items" :key="index">
              <v-col class="required">
                <v-text-field v-model="item.name"
                              outlined
                              :disabled="isEditingItem !== index"
                              :rules="[v => !!v || 'Un nom est requis']"
                              label="Nom"
                              required>
                </v-text-field>
              </v-col>
              <v-col>
                <v-btn icon @click="updateItem(index)" v-if="isEditingItem === index">
                <v-icon>mdi-check</v-icon></v-btn>
                <v-btn icon @click="startUpdateItem(index)" v-if="isEditingItem === null">
                <v-icon>mdi-pencil</v-icon></v-btn>
                <v-btn icon @click="deleteItem(index)" v-if="index !== 0 && isEditingItem === null">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-container>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary"
             text
             @click="closeCatalog">
        OK
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {
  getCatalog, createCatalogItem, updateCatalogItem, deleteCatalogItem,
} from '@/services/catalog.service';
import { mapActions } from 'vuex';

export default {
  name: 'CatalogForm',
  props: ['catalogId'],
  emits: ['closeCatalog'],
  data: () => ({
    newItem: null,
    valid: true,
    isEditingItem: null,
    form: {
      catalog: { name: '' },
      items: [],
    },
  }),
  created() {
    this.fetchCatalog();
  },
  watch: {
    catalogId: {
      handler: 'fetchCatalog',
    },
  },
  methods: {
    async fetchCatalog() {
      getCatalog(this.catalogId).then((value) => {
        this.form = value.data;
      }).catch((err) => console.log(err));
    },
    addItem() {
      if (this.newItem && this.newItem.length > 0 && this.form.catalog.id) {
        createCatalogItem({ name: this.newItem, catalogId: this.form.catalog.id }).then(() => {
          this.newItem = null;
          getCatalog(this.form.catalog.id).then((value) => {
            this.form = value.data;
          })
            .catch((err) => console.log(err));
        });
      }
    },
    startUpdateItem(id) {
      this.isEditingItem = id;
    },
    updateItem(id) {
      if (this.form.catalog.id) {
        updateCatalogItem(this.form.items[id].id, this.form.items[id]).then(() => {
          getCatalog(this.form.catalog.id).then((value) => {
            this.form = value.data;
            this.isEditingItem = null;
          })
            .catch((err) => console.log(err));
        });
      }
    },
    deleteItem(id) {
      if (this.form.catalog.id) {
        deleteCatalogItem(this.form.items[id].id).then(() => {
          getCatalog(this.form.catalog.id).then((value) => {
            this.form = value.data;
          })
            .catch((err) => console.log(err));
        });
      }
    },
    closeCatalog() {
      this.$emit('closeCatalog');
    },
    ...mapActions({
      register: 'auth/register',
    }),
  },
};
</script>

<style scoped>

</style>
