<template>
  <v-container>
    <v-data-table
      :headers="headers"
      :items="propositions"
      :search="search"
      hide-default-footer
      calculate-widths
      :loading="loading"
    >
      <template #top>
        <v-container>
          <v-row align="center">
            <v-btn
              rounded
              color="primary"
              dark
              class="mb-2"
              :to="{ name: 'dashboard_new_proposition' }"
            >
              Ajouter une proposition
              <v-icon right>
                mdi-plus
              </v-icon>
            </v-btn>
            <v-dialog
              v-model="dialogNew"
              persistent
              scrollable
              :fullscreen="$vuetify.breakpoint.mdAndDown"
              max-width="1200px"
            >
              <router-view />
            </v-dialog>
            <v-dialog
              v-model="dialogEdit"
              persistent
              scrollable
              :fullscreen="$vuetify.breakpoint.mdAndDown"
              max-width="1200px"
            >
              <router-view />
            </v-dialog>

            <v-spacer />

            <v-text-field
              v-model="search"
              label="Rechercher"
              append-icon="mdi-magnify"
              single-line
              hide-details
            />
          </v-row>
        </v-container>
      </template>

      <template #item.admin="{ item }">
        <span :class="{
          'font-weight-medium': item.admin,
          'font-weight-regular': !item.admin,
        }">
          {{ item.admin ? 'Administrateur' : 'Client' }}
        </span>
      </template>

      <template #item.proposition="{ item }">
        <router-link
          v-if="item.Proposition"
          :to="getPropositionTo(item.Proposition)"
        >
          Lien
        </router-link>
        <span v-else>Non</span>
      </template>

      <template #no-results>
        Aucun résultat trouvé :/
      </template>

      <template #no-data>
        Aucun abonnement n'a pu être récupéré :/
      </template>

      <template #item.actions="{ item }">
        <v-btn icon
               class="mr-2"
               :to="{ name: 'dashboard_view_proposition',
               params: { id: encodeURIComponent(item.id) }}">
          <v-icon small>mdi-eye</v-icon>
        </v-btn>
        <v-btn icon
               disabled
               class="mr-2"
               :to="{ name: 'dashboard_edit_proposition',
               params: { id: encodeURIComponent(item.id) }}">
          <v-icon small>mdi-pencil</v-icon>
        </v-btn>

        <v-btn disabled icon @click="deleteProposition(item.id)">
          <v-icon small>mdi-delete</v-icon>
        </v-btn>
      </template>

      <template #no-results>
        Aucun résultat trouvé :/
      </template>

      <template #no-data>
        Aucun abonnement n'a pu être récupéré :/
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { getPropositions, deleteProposition } from '../../services/proposition.service';

export default {
  name: 'Propositions',

  created() {
    this.fetchPropositions();
  },

  data: () => ({
    headers: [
      {
        text: 'Type',
        align: 'start',
        value: 'type',
      },
      {
        text: 'Description',
        value: 'description',
      },
      {
        text: 'CDE',
        value: 'cde',
      },
      {
        text: 'Date',
        value: 'date',
      },
      {
        text: 'Progression',
        value: 'percent',
      },
      {
        text: 'Montant',
        value: 'price',
      },
      {
        text: 'Actions',
        sortable: false,
        value: 'actions',
      },
    ],
    loading: false,
    search: null,
    propositions: [],
    dialogNew: false,
    dialogEdit: false,
  }),
  methods: {
    fetchPropositions() {
      this.loading = true;
      getPropositions()
        .then((value) => {
          this.propositions = value.data;
          console.log(this.propositions);
        })
        .catch(console.error)
        .finally(() => {
          this.loading = false;
        });
    },
    getPropositionTo(id) {
      return {
        name: 'dashboard_edit_proposition',
        params: {
          id,
        },
      };
    },
    deleteProposition(id) {
      deleteProposition(id)
        .then(() => {
          console.log('success!');
          this.fetchPropositions();
        })
        .catch(console.error);
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(newRoute) {
        this.dialogNew = newRoute.meta?.showDialogNew;
        this.dialogEdit = newRoute.meta?.showDialogEdit;
        this.fetchPropositions();
      },
    },
  },
};
</script>

<style scoped>

</style>
