<template>
  <v-container>
    <v-data-table
      :headers="headers"
      :items="clients"
      :search="search"
      hide-default-footer
      calculate-widths
      :loading="loading"
    >
      <template #top>
        <v-container>
          <v-row align="center">
            <v-btn
              rounded
              color="primary"
              dark
              class="mb-2"
              :to="{ name: 'dashboard_new_client' }"
            >
              Ajouter un client
              <v-icon right>
                mdi-plus
              </v-icon>
            </v-btn>
            <v-dialog
              v-model="dialogNew"
              persistent
              scrollable
              :fullscreen="$vuetify.breakpoint.mdAndDown"
              max-width="1200px"
            >
              <router-view />
            </v-dialog>

            <v-dialog
              v-model="dialogEdit"
              persistent
              scrollable
              :fullscreen="$vuetify.breakpoint.mdAndDown"
              max-width="1200px"
            >
              <router-view />
            </v-dialog>

            <v-spacer />

            <v-text-field
              v-model="search"
              label="Rechercher"
              append-icon="mdi-magnify"
              single-line
              hide-details
            />
          </v-row>
        </v-container>
      </template>

      <template #item.logo="{ item }">
        <v-img
          color="grey darken-2"
          :src="getSrc(item)"
          width="200"
          height="100"
        />
      </template>

      <template #item.maintenance="{ item }">
        <span :class="{
          'red--text': isMaintaining(item)
        }">{{ getMaintenance(item) }}</span>
      </template>

      <template #item.hosting="{ item }">
        {{ getHosting(item) }}
      </template>

      <template #item.actions="{ item }">
        <v-btn
          icon
          class="mr-2"
          :to="{ name: 'dashboard_view_client'}"
        >
          <v-icon small>mdi-eye</v-icon>
        </v-btn>
        <v-btn icon class="mr-2" :to="{
          name: 'dashboard_edit_client',
           params: {
            clientId: item.id,
          },
        }">
          <v-icon small>mdi-pencil</v-icon>
        </v-btn>
        <v-btn icon @click="deleteCustomer(item.id)">
          <v-icon small>mdi-delete</v-icon>
        </v-btn>
      </template>

      <template #no-results>
        Aucun résultat trouvé :/
      </template>

      <template #no-data>
        Aucun client n'a pu être récupéré :/
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { DateTime } from 'luxon';
import { getCustomers, deleteCustomer } from '@/services/admin.service';

export default {
  name: 'dashboard_index',

  created() {
    this.fetchCustomers();
  },

  data: () => ({
    clients: [],
    headers: [
      {
        text: 'Logo',
        align: 'start',
        sortable: false,
        value: 'logo',
      },
      {
        text: 'Client',
        value: 'name',
      },
      {
        text: 'Maintenance',
        value: 'maintenance',
      },
      {
        text: 'Hébergement',
        value: 'hosting.host',
      },
      {
        text: 'Action',
        value: 'actions',
        sortable: false,
      },
    ],
    search: null,
    dialogEdit: false,
    dialogNew: false,
    loading: false,
  }),
  methods: {
    getMaintenance(item) {
      if (item.maintenance?.until) {
        return DateTime.fromISO(item.maintenance.until).toFormat('dd/MM/yyyy');
      }
      return 'non';
    },
    isMaintaining(item) {
      if (!item.maintenance?.until) return true;
      return DateTime.fromISO(item.maintenance.until).diffNow('day') < 0;
    },
    getHosting(item) {
      return item.hosting?.name;
    },
    fetchCustomers() {
      this.loading = true;
      getCustomers()
        .then((value) => {
          this.clients = value.data;
        })
        .catch(console.error)
        .finally(() => {
          this.loading = false;
        });
    },
    deleteCustomer(id) {
      deleteCustomer(id).then(() => {
        this.fetchCustomers();
      });
    },
    getSrc(item) {
      // substr is necessary because the path starts with / and the api url ends with one aswell
      return `${process.env.VUE_APP_API_URL}${item.logo?.file.path.substr(1)}`;
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(newRoute) {
        this.dialogNew = newRoute.meta && newRoute.meta.showDialogNew;
        this.dialogEdit = newRoute.meta && newRoute.meta.showDialogEdit;
        this.fetchCustomers();
      },
    },
  },
};
</script>

<style scoped>

</style>
