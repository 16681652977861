<template>
  <v-form class="ma-2"
          ref="form"
          v-model="valid"
          lazy-validation>
    <v-dialog v-model="validationDialog"
              type="error"
              width="500">
      <v-alert type="error">
        Certains champs sont invalides.
      </v-alert>
    </v-dialog>
    <v-row align="center">
      <v-col cols="6"
             class="required">
        <v-text-field v-model="newContact.firstname"
                      outlined
                      :rules="[v => !!v || 'Le champ est requis']"
                      label="Prénom"
                      placeholder="Paul"
                      required>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col cols="6"
             class="required">
        <v-text-field outlined
                      v-model="newContact.lastname"
                      :rules="[v => !!v || 'Le champ est requis']"
                      label="Nom"
                      placeholder="Durant"
                      required></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-select v-model="newContact.organisation"
                  v-if="!isInsideOrganisation"
                    :items="organisations"
                    item-text="name"
                    item-value="id"
                    persistent-hint
                    outlined
                    label="Organisation"
                    :loading="loadingOrganisations"></v-select>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col cols="6" class="required">
        <v-text-field outlined
                      v-model="newContact.email"
                      label="Mail"
                      :rules="[(v) => /.+@.+\..+/.test(v) || 'L\'e-mail doit être valide']"
                      placeholder="test@test.com"></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field v-model="newContact.url"
                      v-if="!isInsideOrganisation"
                      outlined
                      label="Url site internet"
                      placeholder="www.monsite.fr"></v-text-field>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col cols="6">
        <v-text-field v-model="newContact.phone_mobile"
                      outlined
                      label="Mobile"
                      placeholder="07 00 00 00 00"></v-text-field>
      </v-col>
      <v-col cols="6" v-if="isInsideOrganisation">
        <v-text-field v-model="newContact.phone"
                      outlined
                      label="Fixe"
                      placeholder="03 00 00 00 00"></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-row class="d-flex flex-nowrap"
               v-if="!isInsideOrganisation">
          <v-select v-model="newContact.owner"
                    persistent-hint
                    outlined
                    :items="owners.items"
                    item-text="name"
                    item-value="id"
                    label="Propriétaire"
                    :loading="loadingOwners"></v-select>
          <v-btn fab
                 x-small
                 dark
                 outlined
                 class="ma-auto ml-2 mt-3"
                 @click="editCatalog(owners.catalog.id)"
                 color="black">
            <v-icon dark>
              mdi-plus
            </v-icon>
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col cols="6" v-if="!isInsideOrganisation">
        <v-text-field v-model="newContact.phone"
                      outlined
                      label="Fixe"
                      placeholder="03 00 00 00 00"></v-text-field>
      </v-col>
      <v-col cols="6" v-if="isInsideOrganisation">
        <v-row class="d-flex flex-nowrap ml-1 mr-1">
          <v-select v-model="newContact.service"
                    persistent-hint
                    outlined
                    :items="services.items"
                    item-text="name"
                    item-value="id"
                    label="Service"
                    :loading="loadingServices"></v-select>
          <v-btn fab
                 x-small
                 dark
                 outlined
                 class="ma-auto ml-2 mt-3"
                 @click="editCatalog(services.catalog.id)"
                 color="black">
            <v-icon dark>
              mdi-plus
            </v-icon>
          </v-btn>
        </v-row>
      </v-col>
      <v-col cols="6">
        <v-text-field v-model="newContact.role"
                      label="Rôle"
                      placeholder="Gérant"
                      outlined></v-text-field>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col cols="6" v-if="!isInsideOrganisation">
        <v-row class="d-flex flex-nowrap">
          <v-select v-model="newContact.service"
                    persistent-hint
                    outlined
                    :items="services.items"
                    item-text="name"
                    item-value="id"
                    label="Service"
                    :loading="loadingServices"></v-select>
          <v-btn fab
                 x-small
                 dark
                 outlined
                 class="ma-auto ml-2 mt-3"
                 @click="editCatalog(services.catalog.id)"
                 color="black">
            <v-icon dark>
              mdi-plus
            </v-icon>
          </v-btn>
        </v-row>
      </v-col>
      <v-col cols="6">
        <v-switch label="Décideur"
                  v-model="newContact.decision_maker" />
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col cols="6">
        <v-dialog v-model="catalogDialog"
                  width="500">
          <CatalogForm :catalogId="selectedCatalog" v-on:closeCatalog="closeCatalog" />
        </v-dialog>
      </v-col>
    </v-row>
    <v-spacer />
    <v-divider inset vertical role="presentation" />
    <v-spacer />
    <v-row class="ma-2 ml-0 d-flex align-center">
      <h2>Formation</h2>
      <v-btn small outlined @click="addFormation" class="ml-2">
        <v-icon dark>
          mdi-plus
        </v-icon>
        <span class="d-sr-only">Ajouter une formation</span>
      </v-btn>
    </v-row>
    <v-spacer />
    <v-divider inset vertical role="presentation" />
    <v-spacer />
    <v-container>
      <v-row v-for="(formation, index) in newContact.formations" :key="index">
        <v-col cols="4" class="required">
          <v-row class="d-flex flex-nowrap">
            <v-select v-model="formation.equipment"
                      persistent-hint
                      outlined
                      :items="products.items"
                      item-text="name"
                      item-value="id"
                      :rules="[v => !!v || 'L\'équipement est requis.']"
                      :label="products.catalog.name"
                      :loading="loadingProducts"
                      required></v-select>
            <v-btn fab
                   x-small
                   dark
                   outlined
                   class="ma-auto ml-2 mt-3"
                   @click="editCatalog(products.catalog.id)"
                   color="black">
              <v-icon dark>
                mdi-plus
              </v-icon>
            </v-btn>
          </v-row>
        </v-col>
        <v-col cols="3" class="pt-0">
          <v-menu v-model="dateDialog"
                  :close-on-content-click="false"
                  :nudge-right="0"
                  transition="scale-transition"
                  offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="formation.date"
                            outlined
                            label="Date"
                            readonly
                            v-bind="attrs"
                            :rules="[v => !!v || 'Une date est requise']"
                            v-on="on"></v-text-field>
            </template>
            <v-date-picker v-model="formation.date"
                           @input="dateDialog = false"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="3" class="required">
          <v-row class="d-flex flex-nowrap ml-2">
            <v-select v-model="formation.level"
                      persistent-hint
                      outlined
                      :items="levels.items"
                      item-text="name"
                      item-value="id"
                      :rules="[v => !!v || 'Le niveau est requis.']"
                      :label="levels.catalog.name"
                      :loading="loadingLevels"
                      required></v-select>
            <v-btn fab
                   x-small
                   dark
                   outlined
                   class="ma-auto ml-2 mt-3"
                   :loading="loadingLevels"
                   @click="editCatalog(levels.catalog.id)"
                   color="black">
              <v-icon dark>
                mdi-plus
              </v-icon>
            </v-btn>
          </v-row>
        </v-col>
        <v-col>
          <v-btn icon @click="deleteFormation(index)"><v-icon>mdi-delete</v-icon></v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import { getCatalogByName } from '@/services/catalog.service';
// import { createContact } from '@/services/contact.service';
import { getOrganisations } from '@/services/organisation.service';
import CatalogForm from '@/components/CatalogForm.vue';
import { mapActions } from 'vuex';
import moment from 'moment';

export default {
  name: 'ContactForm',
  props: ['isEditingContact', 'isInsideOrganisation'],
  components: {
    CatalogForm,
  },
  data: () => ({
    valid: true,
    owners: { catalog: { name: '' }, items: [] },
    services: { catalog: { name: '' }, items: [] },
    organisations: [],
    levels: { catalog: { name: '' }, items: [] },
    products: { catalog: { name: '' }, items: [] },
    loadingServices: false,
    loadingLevels: false,
    loadingProducts: false,
    loadingOwners: false,
    loadingOrganisations: false,
    dateDialog: false,
    validationDialog: false,
    newContact: {
      date: null,
      formations: [],
      decision_maker: false,
    },
    catalogDialog: false,
    selectedCatalog: null,
  }),
  created() {
    this.fetchCatalogs();
    this.$on('validateForm', this.validate);
  },
  mounted() {
    this.newContact = {
      formations: this.isEditingContact && this.isEditingContact.formations
        ? this.isEditingContact.formations.map((el) => ({
          date: el.date ? new Date(el.date).toISOString().substr(0, 10) : null,
          equipment: el.equipment ? el.equipment : null,
          level: el.level,
        })) : [],
      decision_maker: this.isEditingContact ? this.isEditingContact.decision_maker : false,
      role: this.isEditingContact ? this.isEditingContact.role : null,
      email: this.isEditingContact ? this.isEditingContact.email : null,
      firstname: this.isEditingContact ? this.isEditingContact.firstname : null,
      lastname: this.isEditingContact ? this.isEditingContact.lastname : null,
      phone: this.isEditingContact ? this.isEditingContact.phone : null,
      phone_mobile: this.isEditingContact ? this.isEditingContact.phone_mobile : null,
      service: this.isEditingContact ? this.isEditingContact.service : null,
      url: this.isEditingContact ? this.isEditingContact.url : null,
      owner: this.isEditingContact ? this.isEditingContact.owner : null,
      organisation: this.isEditingContact ? this.isEditingContact.organisation : null,
    };
  },
  methods: {
    moment(date) {
      return moment(date);
    },
    fetchCatalogs() {
      this.loadingServices = true;
      getCatalogByName('Service').then((res) => {
        this.services = res.data;
      }).catch((err) => {
        console.log(err);
      }).finally(() => {
        this.loadingServices = false;
      });

      this.loadingOwners = true;
      getCatalogByName('Owner').then((res) => {
        this.owners = res.data;
      }).catch((err) => {
        console.log(err);
      }).finally(() => {
        this.loadingOwners = false;
      });

      this.loadingLevels = true;
      getCatalogByName('Niveau').then((res) => {
        this.levels = res.data;
      }).catch((err) => {
        console.log(err);
      }).finally(() => {
        this.loadingLevels = false;
      });

      this.loadingProducts = true;
      getCatalogByName('Produit').then((res) => {
        this.products = res.data;
        console.log(res.data);
      }).catch((err) => {
        console.log(err);
      }).finally(() => {
        this.loadingProducts = false;
      });

      this.loadingOrganisations = true;
      getOrganisations().then((res) => {
        this.organisations = res.data;
      }).catch((err) => {
        console.log(err);
      }).finally(() => {
        this.loadingOrganisations = false;
      });
    },
    validate() {
      console.log('validating');
      if (this.$refs.form.validate()) {
        if (this.newContact.formations) {
          this.newContact.formations.forEach((el, id) => {
            if (el.level.id) {
              this.newContact.formations[id].level = el.level.id;
            }
            if (el.equipment.id) {
              this.newContact.formations[id].equipment = el.equipment.id;
            }
          });
        }
        console.log('sended formations');
        console.log(this.newContact.formations);
        return this.newContact;
      }
      this.validationDialog = true;
      return null;
    },
    addFormation() {
      console.log('add formation');
      if (!this.newContact.formations) {
        this.newContact.formations = [];
      }

      this.$set(this.newContact.formations, this.newContact.formations.length, {
        equipment: null,
        date: null,
        level: null,
      });

      console.log(this.newContact.formations);
    },
    deleteFormation(id) {
      this.newContact.formations.splice(id, 1);
    },
    editCatalog(catalogId) {
      this.catalogDialog = true;
      console.log(catalogId);
      this.selectedCatalog = catalogId;
    },
    closeCatalog() {
      this.fetchCatalogs();
      this.catalogDialog = false;
      this.selectedCatalog = null;
    },
    ...mapActions({
      register: 'auth/register',
    }),
  },
};
</script>

<style scoped>

</style>
